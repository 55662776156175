import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  WEBSITE_PREVIEW_ITEMS,
  GROCERY_WEBSITE_PREVIEW_ITEMS,
  BAKERY_WEBSITE_PREVIEW_ITEMS,
} from "../../Assets/Constant";
import { Button, Modal } from "../../Components";
import loader from "../../Assets/Images/logo-loader-wb.gif";
import "./PreviewLoader.scss";
import { get } from "lodash";
import { fetchTemplateDetails } from "../../Api/catalogAPI";

export const PreviewLoading = () => {
  return (
    <>
      <div className="PreviewLoader__Modal__Loader">
        {/* <Loader /> */}
        <img src={loader} />
      </div>
      <div className="PreviewLoader__Modal__Content">
        {"Generating a website for you"}
      </div>
    </>
  );
};

export default function PreviewLoader({complete, navigate}) {
  const [activeItemIndex, setActiveItemIndex] = useState("");

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [items, setItems] = useState([]);

  // useEffect(async () => {
  //   const tenant_category = params ? params.get("tenant_category") : "";
  //   const data = {
  //     id: tenant_category,
  //   };
  //   const template = await fetchTemplateDetails(data);
  //   if (template.data.title.toUpperCase() === "GROCERY") {
  //     setItems(GROCERY_WEBSITE_PREVIEW_ITEMS);
  //   } else if (template.data.title.toUpperCase() === "BAKERY") {
  //     setItems(BAKERY_WEBSITE_PREVIEW_ITEMS);
  //   } else {
  //     setItems(WEBSITE_PREVIEW_ITEMS);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (items.length !== 0) {
  //     generateWesitePreview();
  //   }
  // }, [items]);

  const generateWesitePreview = () => {
    for (let i = 1; i <= items.length; i++) {
      addItem(i);
    }

    function addItem(i) {
      setTimeout(function () {
        setActiveItemIndex(i - 1);
        const id = items[i - 1] ? items[i - 1]["id"] : "";
        if (id) {
          const element = document.getElementById(id);
          if (element) {
            const scrollValue =
              element.offsetTop + element.clientHeight > window.innerHeight
                ? element.offsetTop
                : 0;
            window.scroll(0, scrollValue);
          }
        }
      }, 3000 * i);
    }
  };

  // const navigate = () => {
  //   const subdomain = params ? params.get("url") : "";
  //   const subDomainUrl = `https://${subdomain}.aiktech.in/`;
  //   window.location.href = subDomainUrl;
  // };

  const activeItem = items[activeItemIndex];
  const { text = "" } = activeItem || {};
  // const completed =
  //   String(activeItemIndex) && activeItemIndex === items.length - 1;

  const [showModal, toggleModal] = useState(true);

  return (
    <div className="PreviewLoader">
      <Modal show={showModal} onClose={() => {toggleModal(false)}}>
        <div className="PreviewLoader__Modal">
          {
            complete ? 
            (
              <>
                <PreviewLoading />
              </>
            ) : 
            (
              <div className="PreviewLoader__Modal__Success">
                <div className="PreviewLoader__Modal__Success__Text">
                  Your website is ready!
                </div>
                <div className="PreviewLoader__Modal__Success__SubText">
                  Click on the "PREVIEW" button to view the actual website
                </div>
                <div className="PreviewLoader__Modal__Success__Button">
                  <button
                    id="preview"
                    className="btn-active btn-md"
                    onClick={navigate}
                  >
                    Preview
                  </button>
                </div>
              </div>
            )
          }
        </div>
      </Modal>
    </div>
  );
}
