import React from "react";
import { Header, FooterV2, Footer } from "../Components";

export default function PublicRoute({ children }) {
  return (
    <div className="PublicRoute">
      <Header />
      {children}
      {/* <FooterV2 /> */}
      <Footer />
    </div>
  );
}
