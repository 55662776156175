import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./Footer.scss";

import { SOCIALMEDIA_LINKS } from "../../Assets/Constant";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
const mapStateToProps = ({ categories, auth }) => ({
  categories,
  auth,
});

const Footer = ({data}) => {
  const current_year = new Date().getFullYear();
  const {
    categories: { list: categoryList = [] },
    auth,
  } = useSelector(mapStateToProps);

  const { isLoggedIn } = auth;

  const formattedCategories = categoryList;

  const FooterCategoryList = () => {
    return (

      <div className="footer-categories">

        <p style={{
          fontSize: '1.2rem',
          fontWeight: '500',
        }}>Categories</p>

        <div className="footer-categories-contents">

          <ul>
            {
              formattedCategories && formattedCategories.length > 0 ?
                (
                  <>
                    {
                      formattedCategories.map((category, index) => {
                        if (index <= formattedCategories.length / 2) {
                          return (
                            <li>
                              <Link
                                to={`/categories/${category.name}`}
                                key={`category.name`}
                              >
                                {category.name}
                              </Link>
                            </li>
                          );
                        }
                      })

                    }
                  </>
                ) :
                null
            }
          </ul>


          <ul>
            {
              formattedCategories && formattedCategories.length > 0 ?
                (
                  <>
                    {formattedCategories.map((category, index) => {
                      if (index > formattedCategories.length / 2) {
                        return (
                          <li>
                            <Link
                              to={`/categories/${category.name}`}
                              key={`category.name`}
                            >
                              {category.name}
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </>
                )
                :
              null
            }
          </ul>

        </div>
      </div>
    )
  }

  const FooterNavLinks = () => {
    return (
      <ul className="flex flex-col gap-5">
        <li/>
        <li>
          <a
            rel="noreferrer"
          >
            Home
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
          >
            Categories
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
          >
            About Us
          </a>
        </li>


        <li>
          <a
            rel="noreferrer"
          >
            Return Policy
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </li>
        <li>
          <a
            rel="noreferrer"
          >
            Contact Us
          </a>
        </li>

        <li></li>
      </ul>
    )
  }

  const FooterSocialMediaLink = () => {
    return (
      <ul className="flex flex-row gap-10">
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href={SOCIALMEDIA_LINKS['FACEBOOK']}
          >
            <FaFacebook color="rgb(74, 29, 30)" size={'1.3rem'} />
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href={SOCIALMEDIA_LINKS['TWITTER']}
          >
            <FaTwitter color="rgb(74, 29, 30)" size={'1.3rem'} />
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href={SOCIALMEDIA_LINKS['INSTAGRAM']}
          >
            <FaInstagram color="rgb(74, 29, 30)" size={'1.3rem'} />
          </a>
        </li>
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href={SOCIALMEDIA_LINKS['LINKEDIN']}
          >
            <FaLinkedin color="rgb(74, 29, 30)" size={'1.3rem'} />
          </a>
        </li>
        <li>
          <a href="mailto:ziyuzabi@gmail.com">
          </a>
        </li>
      </ul>
    )

  }

  return (
    <div 
      className="footer-container "
      style={{
        backgroundColor : '#fbedcd',
        padding : '2rem 1rem'
      }}
    >
      <div className="flex flex-col gap-20">
        <div className="flex responsive-flex-row gap-20">
          <div 
            className="flex flex-col"
            style={{
              transform : 'translateY(-5px)'
            }}
          >
            <p style={{
              fontSize: '2rem',
              whiteSpace: 'nowrap',
              color: 'rgb(74, 29, 30)',
              fontWeight: '500',
              textTransform : 'uppercase'
            }}>
              {data.title || 'Aiktech'}
            </p>

            <p style={{
              fontSize: '1rem',
              width : '13rem',
              color : 'rgb(74, 29, 30)'
            }}>
              {
                data.desc
              }
            </p>
          </div>

          <div>
            <p 
              className="text-white text-md bold-lg"
              style={{
                color : 'rgb(74, 29, 30)'
              }}
            >Useful Links</p>
            <FooterNavLinks />
          </div>

        </div>

        <div className="flex responsive-flex-row gap-10 justify-between">
          <p 
            style={{ color : 'rgb(74, 29, 30)', fontSize: '1.2rem' }}
          >
            © {current_year} {data.title} All rights reserved.
          </p>
          <FooterSocialMediaLink />
        </div>
      </div>
    </div>
  );
};

export default Footer; 