import {
  storeToken,
  getToken,
  errorMsg,
  formatOptions,
  formatName,
  formatOptionsData,
  validateFormData,
} from "./general-utils";
import {
  validate,
  findError,
  checkValidData,
  isRequired,
} from "./validations-utils";
import { debounce } from "./debounce-utils";
import {
  fetchTenantInupts,
  extractTenantRegistrationErrors,
  extractuserRegistrationError,
} from "./tenant-utils";

export {
  storeToken,
  getToken,
  validate,
  findError,
  checkValidData,
  isRequired,
  errorMsg,
  formatOptions,
  formatName,
  fetchTenantInupts,
  extractTenantRegistrationErrors,
  extractuserRegistrationError,
  formatOptionsData,
  validateFormData,
};
