import { combineReducers } from "redux";
import authReducer from "./authReducer";
import loaderReducer from "./loaderReducer";
import {
  productsReducer,
  categoriesReducer,
  inventoriesReducer,
  stocksReducer,
  sellableInventoriesReducer,
  unitsReducer,
  ordersReducer,
} from "./catalogReducer";
// import { categoriesReducer } from "./categoryReducer";
import {   stockReducer, productsearchReducer, combinationProductReducer, combinationItemProductReducer, paymentReducer } from './cmsReducer';

import {
  subscriptionsReducer,
  tenantReducer,
  tenantCategoriesReducer,
  tenantFormReducer,
  tenantBrandsReducer,
  tenantDetailsReducer,
} from "./tenantReducer";
import { crmReducer } from "./crmReducer";

export default combineReducers({
  loader: loaderReducer,
  auth: authReducer,
  tenantData: tenantReducer,
  tenantCategories: tenantCategoriesReducer,
  subscriptions: subscriptionsReducer,
  tenantForm: tenantFormReducer,
  products: productsReducer,
  categories: categoriesReducer,
  combinationProduct : combinationProductReducer,
  combinationItemProduct : combinationItemProductReducer,
  inventories: inventoriesReducer,
  stocks: stocksReducer,
  sellableInventories: sellableInventoriesReducer,
  units: unitsReducer,
  orders: ordersReducer,
  tenantBrands: tenantBrandsReducer,
  stockdropdown: stockReducer,
  productsearch: productsearchReducer,
  crm: crmReducer,
  companyDetails: tenantDetailsReducer,
  payments : paymentReducer,
});
