import {
  SET_STOCK_DROPDOWN,
  SET_ALL_PRODUCTS,
  SET_COMBINATION_ITEM_PRODUCT,
  SET_COMBINATION_PRODUCT,
  SET_ALL_PAYMENTS,
} from "../actions/actionTypes";

const stockDropdownInitialState = {
  list: {},
};

export const stockReducer = (
  state = stockDropdownInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_STOCK_DROPDOWN: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

const combinationProductInitialState = {
  list: [],
  loading: true,
};

export const combinationProductReducer = (
  state = combinationProductInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_COMBINATION_PRODUCT: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

const combinationItemProductInitialState = {
  list: [],
  loading: true,
};

export const combinationItemProductReducer = (
  state = combinationItemProductInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_COMBINATION_ITEM_PRODUCT: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

const productsearchInitialState = {
  results: [],
  loading: false,
  status: 400,
};

export const productsearchReducer = (
  state = stockDropdownInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_ALL_PRODUCTS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

const paymentsData = {
  results: [],
  loading: false,
  status: 400,
};

export const paymentReducer = (
  state = paymentsData,
  { type, payload }
) => {
  switch (type) {
    case SET_ALL_PAYMENTS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};
