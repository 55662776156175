import React, { useEffect, useState } from 'react'
import './comments.scss'
import { createComment } from '../../../../Api/crmApi';
import { toast } from 'react-toastify';
import { LabelledInput } from "../../../../Components";

const Comments = ({ attributes, ticket, comments, onAddComment, setAllTickets, setTickets }) => {

    const [comment, setComment] = useState('');
    // const [comments, setComments] = useState([]);

    const handleSubmitComment = async () => {
        if(comment=== ""){
            return toast.error("Comment cannot be empty")
        }

        const newComment = {
            comment: comment,
            // commentedBy: 'User',
            ticket_id: ticket.id 
        };

        // setComments(prevComments => [...prevComments, newComment]);
        // onAddComment();
        setComment('');
        sendCommentToBackend(newComment);
    };

    const sendCommentToBackend = (commentData) => {
        // fetch('https://aiktech.in/api/crm/ticket/comments/', {
        //   method: 'POST',
        //   body: JSON.stringify(commentData),
        //   headers: {
        //     'Content-Type': 'application/json'
        //   }
        // })
        // .then(response => response.json())
        // .then(data => {
        //     console.log(data)
        //   console.log('Comment posted successfully:', data);
        // })
        // .catch(error => {
        //   console.error('Error posting comment:', error);
        // });

        createComment(commentData).then((response) => {
            console.log('Comment posted successfully:', response.data.ticket.attributes.comments);
            onAddComment(response.data.ticket);
            setAllTickets((prev) =>
                prev.map((item) =>
                  item.id === ticket.id ? response.data.ticket : item
                )
              );
            
              setTickets(response.data.ticket)
        }).catch((err) => {
            console.error('Error posting comment:', err);
        })
        
    };

    return (
        <div 
            className='comments upload'
            // style={{
            //     overflow : 'hidden',
            // }}
        >
            <div
                className=''
                style={{
                    paddingRight : '10px',
                }}
            >
                <h4
                    style={{
                        margin : '5px 0'
                    }}
                >Comments</h4>

                <div
                    style={{
                        marginTop : '10px',
                        height : '5rem'
                    }}
                >
                    <LabelledInput
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Write a comment..."
                    />
                    <button 
                        onClick={handleSubmitComment} 
                        className="btn-md btn-active-attribute post-btn"
                        style={{
                            marginTop : '1rem',
                            float : 'right'
                        }}
                    >Post</button>
                </div>


                <div 
                    className='relative p-2'
                    style={{
                        marginTop : '2rem'
                    }}
                >
                    {ticket?.attributes?.comments?.map((comment, index) => (
                        comment?.message.length > 0 &&
                        <div 
                            key={index}
                            className="border p-2 round-10"
                            style={{
                                marginBottom : '10px'
                            }}
                        >
                            <p className='commented-user'>{comment.commented_by}</p>
                            <div className='comment px-2'><p>{comment.message}</p></div>
                        </div>
                    )).reverse()}
                </div>

            </div>
        </div>
    )
}

export default Comments