import React from "react";
import Preview from "../Register/Preview/Preview";
import imageUrl from "../../Assets/Images/basic-details-preview.png";
import { LabelledInput, Button } from "../../Components";
import { useState, useEffect } from "react";
import { validate } from "../../Utils/validations-utils";
import { useDispatch, useSelector } from "react-redux";
import { tenantAPI } from "../../Api";
import get from "lodash/get";
import { actionsCreator } from "../../Redux/actions/actionsCreator";
import "./Login.scss";
import { verifyOtp } from "../../Api/authAPI";
import { useHistory } from "react-router-dom";

const mapStateToProps = ({ tenantForm = {} }) => ({
  tenantForm,
});

export default function Login() {
  const {
    tenantForm: { sessionId , errors = {}, isChangeSlide = false },
  } = useSelector(mapStateToProps);

  const [data, setData] = useState({ phone_number: "", otp: "" });
  const dispatch = useDispatch();
  const history = useHistory();
  const [otpToken, setOtpToken] = useState("")

  const onChange = (e, key, nonEvent) => {
    const value = nonEvent ? e : get(e, "target.value", "");
    const updatedData = { ...data, [key]: value };
    setData(updatedData);
  };

  const validateInput = (value, rules, key) => {
    const { error = false, msg = "" } = validate(value, rules);
    const errorObj = { [key]: { error, msg } };
    dispatch(actionsCreator.SET_TENANT_ERRORS(errorObj));
  };

  useEffect(() => {
    dispatch(actionsCreator.RESET_TENANT_FORM_DATA());
  }, []);

  useEffect(() => {
    if (isChangeSlide) {
      navigateToDashboard();
    }
  }, [isChangeSlide]);

  const navigateToDashboard = () => {
    history.push("/register");
  };

  const sendOtp = async () => {
    const { phone_number = "" } = data || {};
    const payload = {
      phone_number,
    };
    dispatch(actionsCreator.SEND_TENANT_USER_OTP(payload));
  };

  const verifySentOtp = () => {
    const { phone_number = "", otp = "" } = data || {};
    const payload = {
      phone: phone_number,
      otp,
      session_id: sessionId,
    };

    dispatch(actionsCreator.VERIFY_TENANT_OTP(payload));
  };

  const showOtpInput = sessionId;

  const resendOTP = async () => {
    const { phone_number = "" } = data || {};
    const payload = {
      phone_number,
    };
    dispatch(actionsCreator.SEND_TENANT_USER_OTP(payload));
  };

  return (
    <div className="Login">
      <div className="Login__Card">
        <div className="Login__Card__Form">
          <div className="Login__Card__Form__Box">
            <div className="BasicDetails Slide__Animate flex flex-col gap-20">
              <h2 className="BasicDetails__Head">Enter your Details</h2>
              <p className="BasicDetails__Subhead">
                You'll need to log in to access your Aik-tech account
              </p>
              <LabelledInput
                label="Phone Number"
                placeholder="Enter Phone number here..."
                value={data.phone_number}
                type="text"
                onChange={(e) => {
                  onChange(e, "phone_number");
                  validateInput(
                    e.target.value,
                    ["isPhone", "isRequired"],
                    "phone_number"
                  );
                }}
                required={true}
                error={errors["phone_number"]}
              />

              {showOtpInput && (
                <LabelledInput
                  label="OTP"
                  placeholder="Enter OTP here..."
                  type="text"
                  value={data.otp}
                  onChange={(e) => {
                    onChange(e, "otp");
                    validateInput(e.target.value, ["isRequired"], "otp");
                  }}
                  error={errors["otp"]}
                />
              )}

            <div className="flex justify-center">
              <button
                id="verifyotp1"
                className="btn-active btn-md"
                onClick={() => {
                  showOtpInput ? verifySentOtp() : sendOtp();
                }}
              >
                {showOtpInput ? "Verify OTP" : "Send OTP"}
              </button>
            </div>
            
            </div>



            {showOtpInput && (
              <div className="RegistrationForm__Back" onClick={resendOTP}>
                Resend OTP
              </div>
            )}

          </div>
          <div className="RegistrationForm__PreviewContainer">
            <Preview previewImage={imageUrl} />
          </div>
        </div>
      </div>
    </div>
  );
}
