import userEvent from "@testing-library/user-event";
import React, { useEffect, useRef, useState } from "react";
import { BsArrowDown, BsArrowDownCircleFill, BsArrowUp, BsArrowUpCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addSellableInventory } from "../../../Api/catalogAPI";
import { addTenantSellableInventory } from "../../../Api/cmsAPI";

import { editTenantUsers, addTenantUser, fetchTenantProducts, fetchInventorys, editTenantSellableInventory } from "../../../Api/cmsAPI";
import { LabelledInput, SideModal } from "../../../Components";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";
import { fetchData } from "../../../Utils/general-utils";
import LinkProduct from "../AddContent/AddCategory/LinkProduct";
import HoverComponent from "../Components/HoverComponent/HoverComponent";

const mapStateToProps = ( {products = {}} ) => ({
  products,
})

function AddSellableInventoryModal({
  closeModal,
  inventoryForm,
  handleResponse,
}) {

  const { products : 
    { list : fullProductList = [] , 
      page : productPage = 1 } 
  } = useSelector(mapStateToProps)

  const dispatch = useDispatch();

  const [item, setItem] = useState({
    id : "",
    quantity_remaining: "",
    address: "",
    expiry : "",
    batch_number : "",
    product: "",
    inventory : "",
  });

  const [inventoryData, setInventoryData] = useState([])
  const [hasMoreInventory, toggleHasMoreInventory] = useState(false);

  useEffect(async () => {

    if(productPage <= 1){
      await fetchMoreProdct()
    }

    if(inventoryForm){
      setItem(inventoryForm);
    }

    try{
      const response = await fetchInventorys();

      const filterSpecificData = response.data?.message.map((item) => {
        return ({
          name : item.name, 
          id : item.id,
        })
      })

      setInventoryData(filterSpecificData)
      toggleHasMoreInventory(false);
    }catch(e){
      toast.error("Error in fetching inventory.");
    }
  }, [])

  const [price, setPrice] = useState();
  const [market_price, setMarketPrice] = useState();
  useEffect(() => {
    if (inventoryForm && inventoryForm.price_variation && inventoryForm?.price_variation?.length !== 0) {
      setPrice(inventoryForm.price_variation[inventoryForm.price_variation.length-1]?.price);
      setMarketPrice(inventoryForm.price_variation[inventoryForm.price_variation.length-1]?.market_price);
    }
  }, []);
  
  const handleChange = (e, payload) => {

    if(e === 'product'){
      setItem((prev) => ({
        ...prev, 
        ["product"] : payload
      }))

      return;
    }

    if(e === "inventory"){
      setItem((prev) => ({
        ...prev, 
        ["inventory"] : payload,
      }))
      
      return;
    }
    
    setItem((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }; 

  // handling linking product
  const [hasMoreProduct, toggleHasMoreProduct] = useState(true);
  const prodPage = useRef(0);
  const [products, setProducts] = useState([]);

  const fetchMoreProdct = async (query) => {
    if(fullProductList.length <= 0){
      return;
    }else{
      const size = 18;

      const items = fetchData( fullProductList , prodPage.current , query , "product_name");

  
      if(items.length < size){
        toggleHasMoreProduct(false);
      }

      const uniqueData = [
        ...new Set([...products, ...items].map((item) => item.id)),
      ].map((id) => [...products, ...items].find((item) => item.id === id));

      setProducts(uniqueData);
  
      prodPage.current += 1;
    }

  };
 
  const [price_variation, setPriceVariation] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!item['product']){
      toast.info("Product is required.")
      return;
    }

    if(!item['inventory']){
      toast.info("Inventory is required.")
      return;
    }

  

    item["product"] = item["product"]["id"];
    item["inventory"] = item["inventory"]["id"];

    if (price) {
      item["price"]= price
    }
    
    if (market_price) {
      item["market_price"]= market_price
    }

    !inventoryForm
      ? addTenantSellableInventory(item)
          .then((res) => {

            res.status === 201 &&
              handleResponse({
                id: res.data.id,
                data: res.data,
              });
            toast.success("Inventory added successfully");
            closeModal(false);
          })
          .catch((err) => {
            toast.error("Error in adding user.");
          })
      : editTenantSellableInventory(item)
          .then((res) => {
            res.status === 200 &&
              handleResponse({
                id: res.data.id,
                data: res.data,
              });
            closeModal(false);
          })
          .catch((err) => {
            toast.error("Error in editing user.");
          });
  };

  const [selectProductModal, toggleSelectProductModal] = useState(false);
  const [selectInventoryModal, toggleSelectInventoryModal] = useState(false);

  const selectProductRef = useRef()
  const selectInventoryRef = useRef()


  return (
    <SideModal title={inventoryForm ? "Edit Sellable Inventory" : "Add sellable Inventory"} onClose={closeModal}>
      <form
        className="add-product-wrapper flex-column gap-10"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <div 
          className="flex-col items-center gap-10 w-full" 
          // style={{
          //     margin : '4px 0'
          // }}
        >
           <div className="text-small text-bold-md">
              Product
            </div>
            <div
              className="w-full relative"
              ref={selectProductRef}
            >
              <div
                className="w-full flex-row justify-between items-center"
                style={{
                  fontSize: '0.8em',
                  fontWeight: 300,
                  border : '1px solid #d2d1d154',
                  padding: '0.5em',
                  marginBottom : '5px'
                }}  

                onClick={() => {
                  toggleSelectProductModal((prev) => !prev);
                }}
              >
                <span className="text-uppercase">
                  {
                    item?.product?.product_name || 'Select Product'
                  }
                </span>
                {selectProductModal ? (
                  <BsArrowUp size={"0.8rem"} color={"black"} />
                ) : (
                  <BsArrowDown size={"0.8rem"} color={"black"} />
                )}
              </div>

              {selectProductModal && (
                <HoverComponent
                  hoverRef={selectProductRef}
                  style={{
                    backgroundColor: "#f2f2f2",
                    width: "100%",
                    height: "10rem",
                    whiteSpace: "normal",
                  }}
                >
                  <LinkProduct
                    searchField={"product_name"}
                    hasMore={hasMoreProduct}
                    toggleHasMore={toggleHasMoreProduct}
                    setPage = {prodPage}
                    fullItem={fullProductList}
                    fetchMore={(query) => {
                      fetchMoreProdct(query);
                    }}
                    onSave={(data) => {
                      handleChange("product", data[0]);
                    }}
                    closeModal={toggleSelectProductModal}
                    isMultiSelect={false}
                    showFullList={true}
                  />
                </HoverComponent>
              )}
            </div>
        </div>
     
        <div 
          className="flex-row items-center gap-10 w-full"
        >
            <div
              className="w-full relative"
              ref={selectInventoryRef}
            >
              <div className="text-small text-bold-md">
                  Inventory
              </div>
              <div
                className="w-full flex-row justify-between items-center"
                style={{
                  fontSize: '0.8em',
                  fontWeight: 300,
                  border : '1px solid #d2d1d154',
                  padding: '0.5em',
                  marginBottom : '5px'
                }}  

                onClick={() => {
                  toggleSelectInventoryModal((prev) => !prev);
                }}
              >
                <span>
                  {
                    item?.inventory?.name || "Select Inventory"
                  }
                </span>
                {selectInventoryModal ? (
                  <BsArrowUp size={"0.8rem"} color={"black"} />
                ) : (
                  <BsArrowDown size={"0.8rem"} color={"black"} />
                )}
              </div>

              {selectInventoryModal && (
                <HoverComponent
                  hoverRef={selectInventoryRef}
                  style={{
                    backgroundColor: "#f2f2f2",
                    width: "100%",
                    height: "5rem",
                    whiteSpace: "normal",
                  }}
                >
                  <LinkProduct
                    searchField={'name'}
                    hasMore={hasMoreInventory}
                    fullItem={inventoryData}
                    fetchMore={() => {return 10}}
                    onSave={(data) => {
                      handleChange("inventory", data[0])
                    }}
                    closeModal={toggleSelectInventoryModal}
                    isMultiSelect={false}
                    showFullList={false}  
                  />
                </HoverComponent>
              )}
            </div>
        </div>
        
        <LabelledInput
          className={"SmallInput"}
          label="Quantity"
          name={"quantity_remaining"}
          placeholder="Enter quantity here..."
          type="number"
          value={item?.quantity_remaining}
          required={true}
          onChange={(e) => {
            handleChange(e);
            // validateInput(e.target.value, ["isRequired"], "product_name");
          }}
          // error={showErrors ? errors["product_name"] : {}}
        />

        <LabelledInput
          className={"SmallInput"}
          label="Address"
          name={"address"}
          placeholder="Enter address here..."
          type="text"
          value={item?.address}
          onChange={(e) => {
            handleChange(e);
            // validateInput(e.target.value, ["isRequired"], "product_name");
          }}
          // error={showErrors ? errors["product_name"] : {}}
        />

        <LabelledInput
          className={"SmallInput"}
          label="Expiry date"
          name={"expiry"}
          placeholder="Enter expiry date here..."
          type="date"
          value={item?.expiry}
          onChange={(e) => {
            handleChange(e);
            // validateInput(e.target.value, ["isRequired"], "product_name");
          }}
          // error={showErrors ? errors["product_name"] : {}}
        />

        <LabelledInput
          className={"SmallInput"}
          label="Batch Number"
          name={"batch_number"}
          placeholder="Enter batch number here..."
          type="text"
          value={item?.batch_number}
          onChange={(e) => {
            handleChange(e);
            // validateInput(e.target.value, ["isRequired"], "product_name");
          }}
          // error={showErrors ? errors["product_name"] : {}}
        />

        <LabelledInput
          className={"SmallInput"}
          label="Your Price"
          name={"price"}
          placeholder="Enter Your price here..."
          type="number"
          value={price}
          onChange={(e) => {
            setPrice(e.target.value)
          }}
          required={true}
        />

        <LabelledInput
          className={"SmallInput"}
          label="Market price"
          name={"market_price"}
          placeholder="Enter Market Price here..."
          type="number"
          value={market_price}
          onChange={(e) => { 
           setMarketPrice(e.target.value)
          }}
        />

        <div
          className="flex-row justify-end gap-10"
          style={{
            marginTop: "2rem 0 ",
          }}
        >
          <button
            className="btn-none btn-inactive-cms"
            onClick={() => closeModal(false)}
          >
            Cancel
          </button>
          <button className="btn-none btn-active-cms" type="submit">
            Save
          </button>
        </div>
      </form>
    </SideModal>
  );
}

export default AddSellableInventoryModal;
