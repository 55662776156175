import React, { useEffect, useRef, useState } from "react";
import AddCategory from "./AddCategory.scss";
import { FiDelete } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import defaultImg from "../../../../Assets/Images/default-image.png";
import { IoAddCircleOutline } from "react-icons/io5";

// apis
import {
  fetchTenantProducts,
  linkProductToCategory,
} from "../../../../Api/cmsAPI.js";

// componens
import InfiniteScroller from "../../Components/InfiniteScrollContainer/InfiniteScrollerContainer";
import { LoadingCard } from "../ContentCards";
import { LabelledInput } from "../../../../Components";
import Loader from "../../Components/Loader";
import useDebounce from "../../../../Utils/debounce-utils";

const TreeView = ({ treeView }) => {
  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(false);

  return (
    <ul className="">
      <li>
        Category : {treeView.categoryName}
        <ul>
          {treeView.subCategoryName !== "" && (
            <li>Subcategory : {treeView.subCategoryName}</li>
          )}
          <ul>
            {treeView.products?.map((item, index) => (
              <li key={index}>
                Product {index + 1 + " "}
                {item}
              </li>
            ))}
          </ul>
        </ul>
      </li>
    </ul>
  );
};

const ItemCard = (props) => {
  return (
    <ul
      className="ul-style-none gap-10"
      style={{
        display: "inline-flex",
        flexDirection: "row",
        width: props.width,
        padding: "4px 10px",
        cursor: "pointer",
        border: "1px solid #f2f2f2",
        marginBottom: "2px",
      }}
      onClick={() => {
        props.onClick(props.data);
      }}
    >
      {
        props.data.photo &&
        <li
          className='content-card-img overflow-hidden'
          style={{
            height: '3.8rem',
            aspectRatio: '1',
            overflow: 'hidden'
          }}
        >
          <img
            src={props.data?.photo}
            // alt={"Not Found"}
            style={{
              objectFit: 'contain',
              width: '100%',
              height: '100%',
              fontSize: '10px',
              justifyContent: 'flex-start',
              display: 'inline-block'
            }}
          />
        </li>
      }
      <li className="flex-column justify-center flex-1">
        <span className="text-medium text-bold-md">{props.data[props.searchField]}</span>

        {
          props.data.description &&
          <span className="text-small text-bold-sm">{props.data?.description}</span>
        }
        {
          props.data.alternate_names &&
          <div style={{display:'flex', columnGap:'5px', flexWrap:'wrap'}}>
            {
              props.data?.alternate_names.map((nam) => <span style={{ fontSize: "10px" }}>{nam}</span>)
            }
          </div>
        }
      </li>
      <li className="flex-row items-center">
        {!props.selectedCard ? (
          <IoAddCircleOutline size={"0.9rem"} color={"#121212"} />
        ) : (
          <MdDelete size={"0.9rem"} color={"#121212"} />
        )}
      </li>
    </ul>
  );
};

const LinkProduct = ({
  searchField,
  fullItem,
  onSave,
  hasMore,
  showFullList,
  toggleHasMore,
  setPage,
  fetchMore,
  closeModal,
  isMultiSelect,
  disabled
}) => {
  const [selectedCard, toggleSelectedCard] = useState([]);

  const handleSave = async (data) => {
    onSave(selectedCard);
    closeModal(false);
  };

  const toggleSelect = (data) => {
    if (isMultiSelect) {
      if (selectedCard.some((item) => item.id === data.id)) {
        const filterCard = selectedCard.filter((item) => item.id !== data.id);
        toggleSelectedCard(filterCard);
        return;
      }

      toggleSelectedCard((prev) => [data, ...prev]);
    } else {
      onSave([data]);
      closeModal(false);
    }
  };

  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 600);
  const handleChange = (e) => {
    if (toggleHasMore) toggleHasMore(true)
    if (setPage) setPage.current = 0;
    setQuery(e.target.value);
  };

  return (
    <div
      className="absolute flex-column border"
      style={{
        width: "100%",
        height: "20rem",
        backgroundColor: "white",
        // border : '1px solid #d2d1d154',
        padding: "4px",
        position: "absolute",
        borderRadius: "8px",
        right: "0",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {
        <>
          <div className="w-full">
            <LabelledInput
              type={"search"}
              placeholder={"Search"}
              value={query}
              onChange={handleChange}
              disabled = {disabled}
            />
          </div>

          {isMultiSelect &&
            selectedCard.length > 0 &&
            (
              <div
                className=""
                style={{
                  overflowX: "scroll",
                  whiteSpace: "nowrap",
                  minWidth: "300px",
                  paddingRight: "20px",
                  marginTop: "10px",
                }}
              >
                {selectedCard.map((item) => {
                  return (
                    <button
                      style={{
                        display: "inline-flex",
                        padding: "4px",
                        marginRight: "10px",
                        marginBottom: "5px",
                        alignItems: "center",
                        border: "1px solid #f2f2f2",
                      }}
                      onClick={() => {
                        toggleSelect(item);
                      }}
                      className="btn-none gap-10"
                    >
                      <span>{item["product_name"]}</span>
                      <MdDelete size={"0.8rem"} color={"black"} />
                    </button>
                  );
                })}
              </div>
            )
          }

          <div
            style={{
              width: "100%",
              padding: "10px 4px",
              flex: "1",
              overflowY: "scroll",
            }}
          >
            <div className="responsive-card-1-1 w-full">
              {
                debouncedQuery.length < 3 && showFullList ?
                  <div className="text-center w-full text-small text-bold-sm">
                    Please type at least 3 character to search...
                  </div>
                  :
                  fullItem
                    .filter((item) => {
                      let onSearchValues = [item[searchField]];
                      if (searchField == "product_name") {
                        onSearchValues.push(...item?.alternate_names,item?.catalogue_number, item?.previous_catalogue_number
                        );
                      }
                      onSearchValues = onSearchValues?.map(val => val?.toLowerCase());
                      return onSearchValues?.some(val => val?.includes(debouncedQuery.toLowerCase()));
                    })
                    .map((item, index) => {
                      return (
                        <div
                          style={{
                            width: "100%",
                            marginBottom: "1%",
                          }}
                        >
                          <ItemCard
                            key={item.id}
                            id={item.id}
                            data={item}
                            searchField={searchField}
                            onClick={toggleSelect}
                            selectedCard={selectedCard.some(
                              (card) => card.id === item.id
                            )}
                            width={"100%"}
                          />
                        </div>
                      );
                    })
              }
              {
                fullItem?.
                  filter((item) => {
                    let onSearchValues = [item[searchField]];
                    if (searchField === "product_name") {
                      onSearchValues.push(...item?.alternate_names,item?.catalogue_number, item?.previous_catalogue_number);
                    }
                    onSearchValues = onSearchValues?.map(val => val?.toLowerCase());
                    return onSearchValues?.some(val => val?.includes(debouncedQuery.toLowerCase()));
                  }).length <= 0 &&
                <div className="text-center w-full text-medium text-bold-sm">
                  No Item to show...
                </div>
              }
            </div>
          </div>

          {
            isMultiSelect &&
            <div
              className="gap-10 flex-column"
              style={{
                padding: "1rem 10px",
                width: "100%",
                overflowX: "hidden",
              }}
            >
              <div
                className="flex-row gap-10"
                style={{
                  justifyContent: "flex-end",
                  maxHeight: "2.2rem",
                  justifySelf: "flex-end",
                }}
              >
                <button type="button" className="btn-none btn-inactive-cms" onClick={() => {
                  closeModal(false);
                }}>
                  Cancel
                </button>
                <button type="button" className="btn-none btn-active-cms" onClick={handleSave}>
                  Done
                </button>
              </div>
            </div>
          }
        </>
      }
    </div>
  );
};

export default LinkProduct;
