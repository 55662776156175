import React from 'react'
import './WhyShop.scss'
// import '../WhyShopCard/WhyShopCard'
// import WhyShopCard from '../WhyShopCard/WhyShopCard';
import feature1Icon from '../../../Assets/Images/why shop with us/icon/icon1.svg';
import feature2Icon from '../../../Assets/Images/why shop with us/icon/icon2.svg';
import feature3Icon from '../../../Assets/Images/why shop with us/icon/icon3.svg';
import whyShopImage1 from '../../../Assets/Images/why shop with us/icon/whyshopwithus1.png';
import whyShopImage2 from '../../../Assets/Images/why shop with us/icon/whyshopwithus2.png';
import rightArrow from '../../../Assets/Images/why shop with us/icon/right-arrow.svg';

import Button from '../Button';

import WhyShopBottomContainer from './BottomContainer/WhyShopBottomContainer';
import useWindowDimensions from '../../../Hooks/useWindowDimensions';

const WhyShopCard = (props) => {
    const { width } = useWindowDimensions();

    return (
        <div className='whyshopcard ' style={{
            flexDirection : width >= 800  ? ( props.row ? 'row' : 'row-reverse' ) : 'column'
        }}>
            <div className='whyshopcard-text-content'>
                <span className='whyshopcard-content-text-head'>
                    {props.text1}
                </span>
                <span className='whyshopcard-content-text-subhead'>
                    {props.text2}
                </span>
                <button className='whyshopcard-content-text-button'>
                </button>
            </div>
            <div className='whyshopcard-image'>
                <img src={props.imageSrc} alt={'whyshopimage-error'} className="whyshopcard-image-insider"/>
            </div>
        </div>
    )
}

const WhyShop = () => {
    return (
        <>
            <div className='w-full h-full'>
                <div className='whyshop-container'>
                    <WhyShopCard
                        row = { true }
                        text1 = {'Cakes infused with love and emotions!'}
                        text2 = {'Our team Bakes with a healthy infusion of Love and emotion.'}
                        buttonText = {'Read More'}
                        imageSrc = {whyShopImage1}
                    />
                    <WhyShopCard
                        row = { false }
                        text1 = {'Come and choose your favourites'}
                        text2 = {'Try to come to our shop directly to enjoy the delicious taste of the cake that just came out of the oven. While enjoying it with a cup of coffee or tea in our very convenient shop. very suitable for chatting, meeting with your team.'}
                        buttonText = {'Read More'}
                        imageSrc = {whyShopImage2}
                    />
                </div>
            </div >
        </>
    )
}

export default WhyShop
