import React from "react";
import ContactUs from "./ContactUs";
import Features from "./Features";
import Hero from "./Hero";
import "./Home.scss";
import LanguageSupport from "./LanguageSupport";
import Offerings from "./Offerings";
import SetupSteps from "./SetupSteps/SetupSteps";
import Testimonials from "./Testimonials/Testimonials";

export default function Home() {
  return (
    <div className="Home">
      <Hero />
      {/* <Features /> */}
      <Offerings />
      <SetupSteps />
      <ContactUs />
      {/* <Testimonials /> */}
      {/* <LanguageSupport /> */}
    </div>
  );
}
