import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Table } from "../../Components";
import { categoryTableColumns } from "../../Utils/table-utils";
import "./Category.scss";
import { actionsCreator } from "../../Redux/actions/actionsCreator";

const mapStateToProps = ({ categories = {} }) => ({
  categories,
});
export default function Category() {
  const {
    categories: { list: categoryList = [] },
  } = useSelector(mapStateToProps);
  const history = useHistory();
  const columns = categoryTableColumns;
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCatogries();
  }, []);

  const fetchCatogries = () => {
    dispatch(actionsCreator.FETCH_CATEGORIES());
  };

  const addCategory = () => {
    history.push(`/dashboard/categories/add`);
  };

  return (
    <div className="Categories">
      <div className="Categories__Actions">
        <Button
          text={"Add Category"}
          className={"Black__Button"}
          onClick={addCategory}
        />
      </div>
      <Table columns={columns} data={categoryList || []} height="100%" />
    </div>
  );
}
