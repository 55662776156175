import { SET_LOGIN, SET_USER_DETAILS } from "../actions/actionTypes";

const initialState = {
  isLoggedIn: false,
  user: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOGIN: {
      return {
        ...state,
        ...payload,
      };
    }
    case SET_USER_DETAILS: {
      return {
        ...state,
        user: payload,
      };
    }
    default:
      return state;
  }
};
