import React, { useCallback, useRef, useState } from "react";
import { LoadingCard } from "../../AddContent/ContentCards";
import Loader from "../Loader";

const InfiniteScroller = ({
  children,
  hasMore,
  fetchMore,
  loader,
  endMessage = "No more items to show"
}) => {
  const [loading, toggleLoading] = useState(false);

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  };

  const fetchItem = async () => {
    try {
      if (!loading && hasMore) {
        toggleLoading(true);
        await fetchMore();
      }
    } finally {
      toggleLoading(false);
    }
  };

  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (!node || loading || !hasMore) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        debounce((entries) => {
          if (entries[0].isIntersecting) {
            fetchItem();
          }
        }, 500),
      );

      observer.current.observe(node);
    },
    [loading, hasMore]
  );

  if (!Array.isArray(children) || children.length === 0) {
    return (
      <>
      <div ref={lastItemRef} />
      </>
    );
  }


  const updatedChildren = children.map((item, index) => {
    if (children.length === index + 1) {
      return React.cloneElement(item, { key: 'lastChild', ref: lastItemRef });
    }
    return item;
  });

  return (
    <>
      {updatedChildren}
    </>
  );
};

export default InfiniteScroller;
