import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "./ContentCard.scss";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { BsCartCheck, BsThreeDotsVertical } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";

import defaultImg from "../../../Assets/Images/default-image.png";

// apis
import {
  deleteProduct,
  deleteCategory,
  deleteProductFromCategory,
  deleteSubCategoryFromCategory,
} from "../../../Api/cmsAPI";

// component
import HoverComponent from "../Components/HoverComponent/HoverComponent";

const ContentCard = (props) => {
  const editRef = useRef(null);

  const handleClickOutside = (event) => {
    const editBtnClass = document.querySelectorAll(".hover-edit-container");

    editBtnClass.forEach((node,ind) => {      
      const parentElement = node.parentElement;
      if (event.currentTarget === parentElement || event.currentTarget === node) {
        node.classList.toggle("active-edit")
      }else{
        node.classList.remove('active-edit')
      }
    });

    const myElement = editRef?.current;
    if (!myElement?.contains(event.target)) {
      editBtnClass.forEach((node) => {
          node.classList.remove('active-edit');
      });
    }

  };

  useEffect(() => {

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [])

  const [deleteLoader, setDeleteLoader] = useState(false);

  const handleDelete = async (e) => {
    e.stopPropagation();

    setDeleteLoader(true);

    if (props.deleteCard?.itemName === "product") {
      try {
        const response = await deleteProduct({ id: props.cardId });
        response.status === 202 &&
          props.deleteCard?.response({
            type: props.deleteCard?.itemName,
            cardId: props.cardId,
          });
      } catch (e) {
        toast.error("Error in deleting product.", { autoClose: 1000 });
      }
    }

    if (props.deleteCard?.itemName === "productFromCat") {
      try {
        const response = await deleteProductFromCategory({
          category_id: props.categoryId,
          id: props.cardId,
        });

        response.status === 202 &&
          props.deleteCard?.response({
            type: props.deleteCard?.itemName,
            cardId: props.cardId,
            catOrSubCatId: props.categoryId,
          });
      } catch (e) {
        toast.error("Error in deleting product.", { autoClose: 1000 });
      }
    }

    if (props.deleteCard?.itemName === "productFromSubCat") {
      try {
        const response = await deleteProductFromCategory({
          category_id: props.subCategoryId,
          id: props.cardId,
        });

        response.status === 202 &&
          props.deleteCard?.response({
            type: props.deleteCard?.itemName,
            cardId: props.cardId,
            catOrSubCatId: props.subCategoryId,
          });
      } catch (e) {
        toast.error("Error in deleting product.", { autoClose: 1000 });
      }
    }
    if (props.deleteCard?.itemName === "category") {
      try {
        const response = await deleteCategory({ id: props.cardId });

        response.status === 202 &&
          props.deleteCard?.response({
            type: props.deleteCard?.itemName,
            cardId: props.cardId,
          });
      } catch (e) {
        toast.error("Error in deleting category.", { autoClose: 1000 });
      }
    }
    if (props.deleteCard?.itemName === "subcategory") {
      try {
        const response = await deleteSubCategoryFromCategory({
          category_id: props.categoryId,
          id: props.cardId,
        });
        response.status === 202 &&
          props.deleteCard?.response({
            type: props.deleteCard.itemName,
            cardId: props.cardId,
          });
      } catch (e) {
        toast.error("Error in deleting subcategory.", { autoClose: 1000 });
      }
    }

    setDeleteLoader(false);
  };

  return (
    <div
      className="content-card-container border w-full p-1"
      onClick={(e) => {
        e.stopPropagation();

        props.selectSubCategory &&
          props.selectSubCategory(e, {
            id: props.data?.id,
            name: props.data?.name,
            data: props.data?.products,
          });

        props.onClick && props.onClick(e);
      }}
    >
      <div className="content-card-wrapper flex-column">
        <div
          id={`editbtn-${props.cardId}`}
          className="content-card-edit btn-none"
          onClick={(event) => {
            event.stopPropagation();
            handleClickOutside(event);
          }}
          ref={editRef}
        >
          <BsThreeDotsVertical
            style={{ cursor: "pointer" }}
            fontSize={"1rem"}
            color={"black"}
          />
          <div className="hover-edit-container relative">
              <HoverComponent
                hoverRef={editRef}
                style={{
                  backgroundColor: "#f2f2f2",
                  padding: "4px 0",
                  width: "10rem",
                  borderRadius: "8px",
                }}
              >
                <div className="flex-column gap-10">

                  {props.editFunction && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        props.editFunction && props.editFunction(props.data);
                      }}
                      className="btn-none nowrap flex-row items-center gap-10 text-small btn-hover"
                    >
                      <CiEdit
                        fontSize={"1.2rem"}
                        style={{ maxWidth: "2rem" }}
                      />{" "}
                      Edit
                    </button>
                  )}

                  <button
                    className="btn-none nowrap flex-row items-center gap-10 text-small btn-hover"
                    onClick={handleDelete}
                  >
                    <MdDeleteOutline
                      fontSize={"1.2rem"}
                      style={{ maxWidth: "2rem" }}
                    />
                    {deleteLoader ? (
                      <span>Unlinking...</span>
                    ) : (
                      <span>Unlink</span>
                    )}
                  </button>

                  {props.addSubcategory && (
                    <button
                      className="btn-none nowrap flex-row items-center gap-10 text-small btn-hover"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.addSubcategory(props.cardId);
                      }}
                    >
                      <IoMdAdd
                        fontSize={"1.2rem"}
                        style={{ maxWidth: "2rem" }}
                      />
                      {"Add Subcategory"}
                    </button>
                  )}
                </div>
              </HoverComponent>
          </div>
        </div>

        <div className="content-card-details">
          <ul className="content-card-ul ul-style-none flex-row gap-10">
            <li
              className="content-card-img overflow-hidden"
              style={{
                height: "3.8rem",
                aspectRatio: "1",
                overflow: "hidden",
              }}
            >
              <img
                src={props.data?.photo || props.data?.image || defaultImg}
                alt={"Not Found"}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                  fontSize: "10px",
                  justifyContent: "flex-start",
                  display: "inline-block",
                }}
                loading={"lazy"}
              />
            </li>

            <li
              className="flex-column justify-center text-bold-md text-medium flex-1"
              style={{ paddingRight: "10px" }}
            >
              <div className="flex-column justify-center text-uppercase">
                <span
                  className="product-title text-bold-md text-medium"
                  style={{}}
                >
                  {props.data?.product_name?.toUpperCase() ||
                    props.data?.name?.toUpperCase() ||
                    "Title"}
                </span>
                <span className="product-description text-bold-sm text-small">
                  {(props.data?.description &&
                    props.data?.description?.substring(0, 100)) ||
                    "Description"}
                </span>

                <span className="product-description text-bold-sm text-small flex-row gap-10 items-center">
                  {props.data?.market_price &&
                    `Market Price : ${props.data?.market_price}`}
                  {props.data?.price && `Our Price : ${props.data?.price}`}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      {
        props.cardId !== null &&
        props.selectedCardId !== null &&
        !props.productCard &&
        props.cardId === props.selectedCardId && 
          (
          props.data?.sub_categories?.length > 0 ?
            <div className="subcategory-container overflow-scroll">

              <span
                className="text-small text-bold-md"
                style={{ marginBottom: "5px" }}
              >
                Sub-Categories
              </span>
              {
                props.data?.sub_categories?.map((item, index) => {
                return (
                  <div
                    key={`sub-${item.id}`}
                    id={`sub-${item.id}`}
                    style={{
                      width: "100%",
                      marginBottom: "1%",
                    }}
                  >
                    <SubContentCard
                      cardId={item.id}
                      data={item}
                      categoryId={props.cardId}
                      selectSubCategory={props.selectSubCategory}
                      deleteCard={props.deleteSubCategory}
                      productCard
                      width={"100%"}
                    />
                  </div>
                );
                }
              )
              }
          </div>
          : 
          (
            <span className="text-small text-bold-sm">
              No SubCategory to show.
            </span>
          )
        
        )
        }
    </div>
  );
};

const SubContentCard = (props) => {
  return <ContentCard {...props} />;
};

export const LoadingCard = ({ fullwidth }) => {
  const isTouchEnabled = "ontouchstart" in window;
  const num = 15;

  // return Array.from({ length: num }, () => 0).map((data, ind) => {
  //   const even = ind % 2 === 0;
    return (
      <div className="w-full">
        <div
          className={`content-card-container border card-loading1 h-full`}
          style={{
            width: "100%",
            padding: "5px",
            display: "flex",
            flexDirection: "row",
            gap: "4px",
          }}
        >
          <div
            style={{
              height: "3.8rem",
              aspectRatio: "1",
              borderRadius: "2px",
              backgroundColor: "#999",
            }}
          />
          <div className="flex-column gap-10 justify-center w-full h-full flex-1">
            <div
              style={{
                backgroundColor: "#999",
                width: "70%",
                height: "1rem",
                borderRadius: "2px",
              }}
            />
            <div
              style={{
                backgroundColor: "#999",
                width: "90%",
                height: "1rem",
                borderRadius: "2px",
              }}
            />
          </div>
        </div>
      </div>
    );
  // });
};

export default ContentCard;
