import React, { useContext, useEffect, useState } from "react";
import "./CardOrders.scss";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { FiEye } from "react-icons/fi";

// apis
import { getBaseUrl } from "../../../../Lib/NetworkHandler";

const CardOrders = ({ order, toggleOrderItem }) => {
  const history = useHistory();

  const formatTime = (time) => {
    const timestamp = new Date(time);
    const formattedDate = `${timestamp.getMonth() + 1}/${timestamp.getDate()}/${
      timestamp.getFullYear() % 100
    },`;
    const period = timestamp.getHours() >= 12 ? "PM" : "AM";
    const hours = timestamp.getHours() % 12;
    const formattedTime = `${hours}:${timestamp.getMinutes()} ${period}`;
    return `${formattedDate} ${formattedTime}`;
  };
  const acceptOrder = async ()=>{
    const response = await axios.post(`${getBaseUrl()}/api/shop/confirm/order/${order.id}`)
  }
  const rejectOrder = async ()=>{
    const response = await axios.post(`${getBaseUrl()}/api/shop/reject/order/${order.id}`)
  }

  const orderItemsDisplay = () => {
    const config = {
      params: {
        id: order.id,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    localStorage.setItem(`${order.id}`, "1");
    axios
      .get(`${getBaseUrl()}/api/shop/order/items/`, config)
      .then((response) => {
        const data = response.data.message;
        // history.push({ pathname: '/orderItems', state: data })
        toggleOrderItem(data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  return (
    <div className="cardorder">
      <div
        className="text-large text-bold-md cardorder-heading flex-row items-center justify-between"
        style={
          localStorage.getItem(`${order.id}`) === "1"
            ? { backgroundColor: "rgb(248,222,197)", color: "black" }
            : {
                backgroundColor: "rgb(215,234,215)",
                color: "black",
              }
        }
      >
        <span>{order.status}</span>

        <button className="btn-none" onClick={orderItemsDisplay}>
          <FiEye fontSize={"1.2rem"} width={"2rem"} color={"white"} />
        </button>
      </div>

      <ul
        className="ul-style-none flex-column gap-10 text-small font-bold-sm"
        style={{ padding: "10px 10px" }}
      >
        <li className="">
          {" "}
          <span className="text-bold-md"> Order Id : </span>
          {order.id}
        </li>
        <li className="">
          <span className="text-bold-md"> Total Price: </span>{" "}
          {parseFloat(order.total_price).toFixed(2)}
        </li>
        <li className="">
          <span className="text-bold-md"> Created At : </span>
          {formatTime(order.created_on)}
        </li>

        <li className="">
          <span className="text-bold-md"> Customer Name : </span>
          {order.customer.name}
        </li>

        <li className="">
          <span className="text-bold-md"> Contact Number : </span>
          {order.customer.phone_number}
        </li>

        <li className="" style={{ whiteSpace: "normal" }}>
          <span className="text-bold-md"> Address : </span>{" "}
          {order.fulfilment_address}
        </li>
      </ul>

      <div
        className="w-full flex-row justify-end gap-10"
        style={{padding : '0 1rem'}}
      >
        {
          order.status === "INITIALISED"?(
            <>
            <button
          type="button"
          onClick={rejectOrder}
          className="btn-none btn-inactive"
        >
          Cancel
        </button>
        <button type="button" 
        onClick={acceptOrder}
        className="btn-none btn-active">
            Accept
        </button>
        </>
          ):(
            <div></div>
          )
        }
      </div>
    </div>
  );
};

export default CardOrders;
