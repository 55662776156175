import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  HomeV2,
  Login,
  PreviewLoader,
  PreviewSlide,
  Pricing,
  Register,
} from "../Containers";


import PublicRoute from "./PublicRoute";
import DashboardRoutes from "./DashboardRoutes";
import { AdminWrapper, AuthWrapper } from "../Wrapper";
import Carditems from '../Containers/OrderItems/cardItems/CardItems'
import Ticket from "../Containers/Crm/Ticket/Ticket";
import Builder from "../Containers/TemplateBuilder/Builder";
import LazyLoad from "../Components/LazyLoad/LazyLoad";
import Home from '../Templates/aiktech-bakery/src'

const TenantDashboard = lazy(() => import('../Containers/Cms/TenantDashboard/TenantDashboard'));

const TenantDashboardTicket = lazy(() => import('../Containers/Crm/TenantDashboard/TenantDashboard'));


export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <Switch>
            <Route
              exact
              path="/"
              component={() => (
                <PublicRoute>
                  <HomeV2 />
                </PublicRoute>
              )}
            />
            <Route
              exact
              path="/register"
              component={() => (
                <PublicRoute>
                  <Register />
                </PublicRoute>
              )}
            />
            <Route
              exact
              path="/login"
              component={() => (
                <PublicRoute>
                  <Login />
                </PublicRoute>
              )}
            />
            <Route
              exact
              path="/tenant-preview"
              component={() => (
                <PublicRoute>
                  <PreviewSlide />
                </PublicRoute>
              )}
            />
            <Route
              exact
              path="/pricing"
              component={() => (
                <PublicRoute>
                  <Pricing />
                </PublicRoute>
              )}
            />
            <Route
              path="/dashboard"
              component={() => (
                // <AuthWrapper>
                <DashboardRoutes />
                // </AuthWrapper>
              )}
            />
            <Route
              path="/cms"
              component={() => (
                <AdminWrapper>
                  <LazyLoad>
                    <TenantDashboard />
                  </LazyLoad>
                </AdminWrapper>
              )}
            />
            <Route
              path="/crm"
              component={() => (
                <AdminWrapper>
                  <LazyLoad>
                    <TenantDashboardTicket />
                  </LazyLoad>
                </AdminWrapper>
              )}
            />
            <Route
              path="/builder"
              component={() => (
                <PublicRoute>
                  {/* <Home/> */}
                  <Builder />
                </PublicRoute>
              )}
            />
            <Route
              path="*"
              component={() => (
                <PublicRoute>
                  <HomeV2 />
                </PublicRoute>
              )}
            />
          </Switch>
        </Route>
      </Switch>
    </Router>
  );
}
