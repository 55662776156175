import requestMaker from "../Lib";

export const addProduct = (data) => {
  const url = `/account/dashboard/products/`;
  const params = {};
  const payload = data;
  return requestMaker(url, "post", { params, payload });
};

export const updateProduct = (data, id) => {
  const url = `/account/dashboard/products/${id}/`;
  const params = {};
  const payload = data;
  return requestMaker(url, "put", { params, payload });
};

export const fetchProducts = (data) => {
  const url = `/account/dashboard/products/`;
  const params = { ...data };
  const payload = {};
  return requestMaker(url, "get", { params, payload });
};
export const postContract =(data)=>{
  const url = '/shop/contracts/upload/'
  const params = {}
  const payload = data
  return requestMaker(url,'post',{params,payload})
}
export const fetchContract =()=>{
  const url = '/shop/contracts/'
  const params = {}
  const payload = {}
  return requestMaker(url,'get',{params,payload})
} 
export const postChallan =(data)=>{
  const url = '/shop/challans/'
  const params = {}
  const payload = data
  return requestMaker(url,'post',{params,payload})
}

export const fetchChallan =(offset,limit=10)=>{
  const url = `/shop/challans/?limit=${limit}&offset=${offset}`;
  const params = {}
  const payload = {}
  return requestMaker(url,'get',{params})
}
export const fetchContractbyId =(id)=>{
  const url = `/shop/contracts/${id}/`
  const params = {}
  const payload = {}
  return requestMaker(url,'get',{params,payload})
} 
//category api
export const addCategory = (data) => {
  const url = `/account/dashboard/categories/`;
  const params = {};
  const payload = data;
  return requestMaker(url, "post", { params, payload });
};

export const updateCategory = (data, id) => {
  const url = `/account/dashboard/categories/${id}/`;
  const params = {};
  const payload = data;
  return requestMaker(url, "put", { params, payload });
};

export const fetchCatogries = (data) => {
  const url = `/account/dashboard/categories/`;
  const params = { ...data };
  const payload = {};
  return requestMaker(url, "get", { params, payload });
};

//Inventory api
export const addInventory = (data) => {
  const url = `/account/dashboard/inventory/`;
  const params = {};
  const payload = { ...data };
  return requestMaker(url, "post", { params, payload });
};

export const updateInventory = (data) => {
  const url = `/account/dashboard/inventory/${data.id}/`;
  const params = {};
  const payload = { ...data };
  return requestMaker(url, "put", { params, payload });
};

export const fetchInventories = (data) => {
  const url = `/account/dashboard/inventory/`;
  const params = { ...data };
  const payload = {};
  return requestMaker(url, "get", { params, payload });
};

//stock api
export const addStock = (data) => {
  const url = `/account/dashboard/stock/`;
  const params = {};
  const payload = { ...data };
  return requestMaker(url, "post", { params, payload });
};

export const updateStock = (data) => {
  const url = `/account/dashboard/stock/${data.id}/`;
  const params = {};
  const payload = { ...data };
  return requestMaker(url, "put", { params, payload });
};

export const fetchStocks = (data) => {
  const url = `/account/dashboard/stock/`;
  const params = { ...data };
  const payload = {};
  return requestMaker(url, "get", { params, payload });
};

//Sellable Inventory api
export const addSellableInventory = (data) => {
  const url = `/account/dashboard/sellableinventory/`;
  const params = {};
  const payload = { ...data };
  return requestMaker(url, "post", { params, payload });
};

export const updateSellableInventory = (data) => {
  const url = `/account/dashboard/sellableinventory/${data.id}/`;
  const params = {};
  const payload = { ...data };
  return requestMaker(url, "put", { params, payload });
};

export const fetchSellableInventories = (data) => {
  const url = `/shop/tenant/sellableinventories/all/`;
  const params = { ...data };
  const payload = {};
  return requestMaker(url, "get", { params, payload });
};

export const fetchUnits = (data) => {
  const url = `/account/dashboard/stockunits/`;
  const params = { ...data };
  const payload = {}; 
  return requestMaker(url, "get", { params, payload });
};

//Order api
export const updateOrder = (data) => {
  const url = `/account/dashboard/order/${data.id}/`;
  const params = {};
  const payload = { ...data };
  return requestMaker(url, "put", { params, payload });
};

export const fetchOrders = (data) => {
  const url = `/account/dashboard/order/`;
  const params = { ...data };
  const payload = {};
  return requestMaker(url, "get", { params, payload });
};

export const fetchTemplateDetails = (data)=>{
  const url = `/shop/template/details/`;
  const params = {  };
  const payload = {...data};
  return requestMaker(url, "post", { params, payload });
}
