import React from "react";
import "./SellableInventory.scss";

export default function ScalableInventory({ productDetails, handleEdit }) {
    console.log(productDetails)
    return (
        <div className="product-container">
            <div className="product-desc" style={{ flex: '3' }}>
                <div className="left-side">
                    <img src={productDetails.product.photo} alt="" width="40px" height="40px" />
                </div>
                <div className="right-side">
                    <p>{productDetails.product.product_name}</p>
                    <div style={{margin:'auto'}}>
                        <div style={{ display: "flex", justifyContent: "flex-start", width: '100%', gap: '10px', padding: "0px 5px" }}>
                            <p style={{ flex: '1.5', marginBottom: "0px", textAlign: 'left' }}><span>Descripition :- </span>{productDetails.product.description}</p>
                            <p style={{ flex: '1', marginBottom: "0px", textAlign: 'left' }}><span>Batch No. :- </span>{productDetails.batch_number || 'NA'}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px", width: '100%', padding: "0px 5px" }}>
                            <p style={{ fontSize: '10px', marginBottom: '0', flex: '1.5', textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Catalogue No. </span>{productDetails.catalogue_number || "NA"}</p>
                            <p style={{ fontSize: '10px', marginBottom: '0', flex: '1', textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Serial No. :- </span>{productDetails.product.barcode || 'NA'}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div>{productDetails?.inventory.name}</div>
            <div>{productDetails?.quantity_remaining}</div>
            <div>{productDetails?.address}</div>
            <div>{productDetails?.rate}</div>
            <div>{productDetails?.manufacture}</div>
            <div>{productDetails?.expiry}</div>
            <div onClick={handleEdit}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6" style={{ height: '25px' }}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                </svg>

            </div>
        </div>

    )
}