import React, { useEffect, useMemo, useRef, useState } from "react";

import './AddSellableInventory.scss'
import { toast } from "react-toastify";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";

// apis
import { deleteTenantSellableInventory, addTenantSellableInventory,
deleteTenantUsers, fetchTenantUsers, fetchInventorys, 
sellableProductSearch } from "../../../Api/cmsAPI";


// components
import { LabelledInput, Modal } from "../../../Components";
import HoverComponent from "../Components/HoverComponent/HoverComponent";
import { LoadingCard } from "../AddContent/ContentCards";
import AddStockModal from "./AddSellableInventoryModal";
import { fetchTenantSellableInventories } from "../../../Api/cmsAPI";
import InfiniteScroller from "../Components/InfiniteScrollContainer/InfiniteScrollerContainer";
import { FiFilter } from "react-icons/fi";
import Loader from "../Components/Loader";
import SearchSuggestion from "../Components/SearchSuggestion/SearchSuggestion";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../Utils/general-utils";
import useDebounce from "../../../Utils/debounce-utils";
import { isArray } from "lodash";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";
import SellableInventory from "../../../Components/SellableInventory/SellableInventory";

const UserCard = (props) => {
  const editRef = useRef(null);
  const handleClickOutside = (event) => {

    const editBtnClass = document.querySelectorAll(".hover-edit-container");

    editBtnClass.forEach((node, ind) => {
      const parentElement = node.parentElement;
      if (event.currentTarget === parentElement || event.currentTarget === node) {
        node.classList.toggle("active-edit")
      } else {
        node.classList.remove('active-edit')
      }
    });

    const myElement = editRef?.current;
    if (!myElement?.contains(event.target)) {
      editBtnClass.forEach((node) => {
        node.classList.remove('active-edit');
      });
    }

  };

  useEffect(() => {

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [])


  const handleEdit = () => {
    props.editFunction(props.data);
  };

  const handleDelete = () => {
    deleteTenantSellableInventory({ id: props.id })
      .then((res) => {
        res.status === 202 && props.deleteFunction(props.id);
      })
      .catch((err) => {
        toast.error("Error in deleting user.");
      });
  };

  const price_variation = props.data?.price_variation;
  const price = price_variation[price_variation?.length - 1]?.price;
  // const price = 10;

  return (
    <SellableInventory productDetails={props.data} handleEdit={handleEdit} />
  );
};

const mapStateToProps = ({ inventories = {} }) => ({
  inventories,
});

const AddSellableInventory = () => {
  const {
    inventories: { list: invList = [], loading: loadingInv = false },
  } = useSelector(mapStateToProps);

  const [fullInventory, setFullInventory] = useState([]);
  const filterRef = useRef(null);

  const [filterOption, toggleFilterOption] = useState(null)
  const [showFilter, toggleFilter] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  // const invPage = useRef(0);
  const [invPage, setInvPage] = useState(0);

  const fetchItem = async () => {
    if (!hasMore) return;

    const size = 18;

    if (!isArray(invList)) {
      setHasMore(false);
      return;
    }

    if (invList.length <= 0 && loadingInv === false) {
      setHasMore(false);
    }

    if (invList.length <= 0) return;

    const items = fetchData(invList, invPage);


    if (items.length < size) {
      setHasMore(false);
    }

    const uniqueData = [
      ...new Set([...fullInventory, ...items].map((item) => item.id)),
    ].map((id) => [...fullInventory, ...items].find((item) => item.id === id));


    // invPage.current += 1;
    setInvPage(prev => prev + 1);
    setFullInventory(uniqueData);

  };

  const [allInventories, setAllInventories] = useState([]);
  const fetchAllInventories = async () => {
    try {
      const result = await fetchInventorys();
      setAllInventories(result.data.message);
    } catch (e) {
      console.error(e.message);
    }
  }

  useEffect(async () => {
    fetchAllInventories();

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const [addOrEditModal, toggleAddOrEditModal] = useState(false);

  const [inventoryForm, setInventoryForm] = useState({
    id: "",
    quantity_remaining: "",
    address: "",
    expiry: "",
    batch_number: "",
    product: "",
    inventory: "",
  });

  // Modal View and toggleEdit Button
  const handleToggleModal = () => {
    toggleAddOrEditModal((prev) => !prev);
  };

  const [edit, setEdit] = useState(false);

  const handleEditButton = (data) => {
    setInventoryForm(data);
    setEdit(true);
    handleToggleModal();
  };

  const handleDelete = (data) => {
    const newUser = fullInventory.filter((item) => item.id !== data);
    setFullInventory(newUser);
  };

  const dispatch = useDispatch();
  const handleEditSuccess = (data) => {
    const index = fullInventory.findIndex((item) => item.id === data.id);

    let newArrayList = [];
    if (index === -1) {
      newArrayList = [data.data, ...fullInventory];
      dispatch(actionsCreator.SET_INVENTORIES({ list: newArrayList }));

      newArrayList = [data.data, ...fullInventory];
    } else {
      newArrayList = fullInventory;
      newArrayList[index] = data.data;
      dispatch(actionsCreator.SET_INVENTORIES({ list: newArrayList }));

      newArrayList = fullInventory;
      newArrayList[index] = data.data;
    }

    setFullInventory([...newArrayList]);

  };

  // search Functionality
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 600);
  const searchRef = useRef(null);
  // useEffect(() => {
  //   fetchItem();

  //   return () => {
  //     invPage.current = 0
  //   }
  // }, [debouncedQuery, loadingInv])

  const handleClickOutside = (event) => {
    const myElement = searchRef?.current;
    if (myElement && !myElement.contains(event.target)) {
      setQuery('')
    }

    if (filterRef && !filterRef?.current?.contains(event.target)) {
      toggleFilter(false);
    }
  }



  return (
    <div className="add-content-container h-full w-full">
      {addOrEditModal && (
        <AddStockModal
          closeModal={toggleAddOrEditModal}
          inventoryForm={inventoryForm}
          handleResponse={handleEditSuccess}
        />
      )}

      <div className="add-content-wrapper flex-column">
        <section className="flex-row items-center gap-10 flex-1 relative">
          <div ref={searchRef} className="h-full w-full">
            <LabelledInput
              type={"search"}
              placeholder={"Please type atleast 3 characters to search..."}
              value={query}
              onChange={
                (event) => {
                  setQuery(event.target.value);
                }
              }
              disabled={loadingInv}
            />
            {
              debouncedQuery.length >= 3 &&
              <HoverComponent
                hoverRef={searchRef}
                className='search-parent'
                style={{
                  maxHeight: "60vh",
                  width: '100%',
                  backgroundColor: "white",
                  padding: '10px',
                  overflowY: "scroll",
                  overflowX: "hidden",
                  boxShadow: '0px 10px 10px #888',
                  borderRadius: '10px',
                  marginTop:'15px',
                }}
              >
                {
                  invList
                    .filter((item) => {
                      return item.product.product_name.toLowerCase().includes(debouncedQuery.toLowerCase());
                    })
                    .map((user, index) => (
                      <div className="search-parent">
                        <UserCard
                          key={user.id}
                          id={user.id}
                          data={user}
                          editFunction={handleEditButton}
                          deleteFunction={handleDelete}
                        />
                      </div>
                    ))
                }

                {
                  fullInventory
                    .filter((item) => {
                      return item.product.product_name.toLowerCase().includes(debouncedQuery.toLowerCase());
                    }).length <= 0 &&
                  <div
                    className="text-center"
                    style={{
                      height:'10vh',
                      marginTop:'20px'
                    }}
                  >
                    No Items to show
                  </div>
                }
              </HoverComponent>
            }

          </div>

          <div
            className="relative"
            ref={filterRef}
          >
            <button
              className="btn-none"
              onClick={() => {
                toggleFilter(prev => !prev);
              }}
            >
              <FiFilter size={'1.2rem'} />
            </button>

            {
              showFilter &&
              <HoverComponent
                hoverRef={filterRef}
                style={{
                  backgroundColor: "#f2f2f2",
                  padding: "4px 0",
                  // width: "8rem",
                  padding: '5px 10px',
                  borderRadius: "8px",
                  zIndex: '1000',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <button
                  className={`btn-none text-small ${filterOption === null && 'text-bold-lg'}`}
                  onClick={() => {
                    toggleFilterOption(null)
                    setHasMore(true);
                    setPage(1);
                  }}
                >
                  All
                </button>
                {
                  allInventories.map((item) => {
                    return (
                      <button
                        className={`btn-none text-small ${filterOption?.id == item.id && 'text-bold-lg'}`}
                        onClick={() => {
                          toggleFilterOption(item)
                          setHasMore(true);
                          setPage(0);
                        }}
                      >
                        {
                          item.name
                        }
                      </button>
                    )
                  })
                }
              </HoverComponent>
            }

          </div>

          <button
            onClick={() => {
              setInventoryForm(null);
              handleToggleModal();
            }}
            className="btn-none btn-active-cms"
          >
            {`Add to inventory`}
          </button>
        </section>
        <ul className="products-list-heading">
          <li>Product Description</li>
          <li>Inventory</li>
          <li>Quantity Remaining</li>
          <li>Address</li>
          <li>Net Rate</li>
          <li>Manufacture Date</li>
          <li>Expiry Date</li>
          <li>Edit</li>
        </ul>

        <div>
          <section className="w-full items-container" style={{display:'flex', flexDirection:'column', height:'70vh', overflowY:'auto'}}>
              <InfiniteScroller
                hasMore={hasMore}
                fetchMore={fetchItem}
                loader={<LoadingCard />}
                endMessage={"No more categories to show."}
              >
                {
                  (
                    fullInventory
                      .filter((item) => {
                        var check = filterOption === null ? true : filterOption?.name === item.inventory.name;
                        return (
                          check
                        )
                      })
                      .map((user, index) => (
                        <div className="parent" >
                          <UserCard
                            key={user.id}
                            id={user.id}
                            data={user}
                            editFunction={handleEditButton}
                            deleteFunction={handleDelete}
                          />
                        </div>
                      ))
                  )}
              </InfiniteScroller>
            {
              // !loadingInv && fullInventory.length > 0 &&
            }
          <div className="flex-row justify-center items-center w-full">
            {
              hasMore ?
                <Loader /> :
                <p>
                  No more items to show
                </p>
            }
          </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddSellableInventory;