import React from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';

const LoadComponent = ({
    children,
    animationDelay
}) => {

    const targetRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
            entry.target.classList.add('appear-from-bottom');
            observer.disconnect();
            }
        });
        },);

        // Start observing the target element
        if (targetRef.current) {
        observer.observe(targetRef.current);
        }

        // Cleanup by disconnecting the observer when the component unmounts
        return () => {
        observer.disconnect();
        };
    }, [targetRef]);


  return (
    <div 
        ref={targetRef}
        // className={`${''}`}
        style={{
            opacity : '0',
        }}
    >
        {
            children
        }
    </div>
  )
}

export default LoadComponent