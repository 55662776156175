import React, { useEffect, useRef, useState } from "react";
// import "./Dashboard.scss";
import { IoMdNotificationsOff, IoIosNotificationso } from "react-icons/io";
import { toast } from "react-toastify";
import sound from "./Message notification.mp3";
import { FaCopy } from "react-icons/fa";
// apis
import requestMaker, { getBaseUrl } from "../../../Lib/NetworkHandler";
import { IoArchive,IoArrowUndo } from "react-icons/io5";
import axios from 'axios'; // Assuming you're using axios for API calls
// import Modal from './Modal'; // Adjust the path as needed
// import RoundButton from '../';
// components
import Loader from "../Components/Loader/Loader";
import CardOrders from "../Components/cardOrders/CardOrders";
import { Modal } from "../../../Components";
import Carditems from "../../OrderItems/cardItems/CardItems";
import "./Ticket.scss";
import { FaComment } from "react-icons/fa";
import { IoIosAttach } from "react-icons/io";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { update } from "lodash";
import { FaPlus } from "react-icons/fa";

import AddSellableInventory from "../../Cms/AddSellableInventory/AddSellableInventory";
import AddSellableInventoryModal from "../../Crm/AddSellableInventory/AddSellableInventoryModal";
import {
  archiveTicket ,
  unarchiveTicket ,
  changeDoctors,
  createTicket,
  edit_invoice_status,
  fetch_invoice_status,
  fetchTicket,
  fetchWorkflows,
  ticketTransition,
} from "../../../Api/crmApi";

import Attribute from "../Components/Attributes/Attribute";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";
import { IoEye } from "react-icons/io5";
import Field from "./Fields/Field";
import { useDrag } from "react-dnd";
import DraggableDiv from "./DraggableDiv";
import DroppableDiv from "./DroppableDiv";
import { GoMoveToTop } from "react-icons/go";
import MoveButton from "./MoveButton";
import { FiDelete } from "react-icons/fi";

const mapStateToProps = ({ crm = {}, companyDetails }) => ({
  crm,
  companyDetails,
});

const Ticket = ({ activeComponent }) => {
  const { crm, companyDetails } = useSelector(mapStateToProps);

  const [orders, setOrders] = useState([]);
  const [notifications, setNotifications] = useState(false);
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [display, setDisplay] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showProductForm, setShowProductForm] = useState(false);
  const [showAttributeForm, setShowAttributeForm] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [columns, setColumns] = useState([]);
  const [column1Cards, setColumn1Cards] = useState(["Card 1", "Card 2"]);
  const [column2Cards, setColumn2Cards] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [addOrEditModal, toggleAddOrEditModal] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggleModal = () => {
    toggleAddOrEditModal((prev) => !prev);
  };

  const [ticketId, setTicketId] = useState(null);
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [attributes, setAttributes] = useState({});
  const [selectedTicketAttributes, setSelectedTicketAttributes] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState([]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const [ticketType, setTicketType] = useState("");
  const [ticketTenant, setTicketTenant] = useState("");
  const [workflowId, setWorkflowId] = useState(null);
  const [workflowTickets, setWorkflowTickets] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [comments, setComments] = useState([]);
  const [ticketLayout, setTicketLayout] = useState({});
  const [comment, setComment] = useState("");
  const [invoice_statuses, setInvoiceStatutes] = useState(null);
  const [selected_invoice_status, SetSelectedInvoiceStatus] = useState();
  const [archivedTickets, setArchivedTickets] = useState([]);

  const handleAddComment = (ticket) => {
    // setTickets((prev)=>(prev.id===ticketID ? updatedTicket : prev))
    // setSelectedTicket(selectedTicket.id === newComment.ticket_id ? { ...selectedTicket, attributes: { ...selectedTicket.attributes, comments: [...selectedTicket.attributes.comments, newComment] } } : selectedTicket)

    // setSelectedTicket(prev => ({...prev, attributes: {...prev?.attributes, comments: [...prev?.attributes?.comments, newComment]}}))
    setSelectedTicket(ticket);

    setTickets((prev) =>
      prev.map((item) =>
        item.id === ticket.id ? ticket : item
      )
    );

  };
  console.log(tickets,"tickets");

  const fetchInvoiceStatuses = async ()=>{
    try {
    const res = await fetch_invoice_status();
    setInvoiceStatutes(res.data.data)
    } catch (error) {
        console.log(error)
    }
}

  // useEffect(() => {
  //   const fetchWorkflows = async () => {
  //     try {
  //       // Fetch workflows data from backend API
  //       // if (companyDetails?.list.length !== 0 && workflows.length !== 0) {

  //       const response = await fetch(`${getBaseUrl()}/api/crm/workflows/16/`);
  //       // const response = await fetch(`https://www.aiktech.in/api/crm/workflows/${companyDetails?.list?.id}/`);
  //       const data = await response.json();
  //       dispatch(actionsCreator.SET_WORKFLOW_CONFIGS({ workflow: data }));
  //       setWorkflows(data);
  //       // }
  //       // if (data && data.length > 0) {
  //       //   const firstWorkflowId = data[0].workflow_config.id;
  //       //   setWorkflowId(firstWorkflowId);
  //       // }
  //     } catch (error) {
  //       console.error("Error fetching workflows:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  // });

  // const updateTicket = (updatedTicket, filesUrl) => {
  //   const idOfTicket = tickets.findIndex(element => element.id === updatedTicket)
  //   // setTickets((prevTickets) =>
  //   //   prevTickets.map((ticket) =>
  //   //     // ticket.id === updatedTicket.id ? updatedTicket : ticket
  //   //     idOfTicket
  //   //   )
  //   // )
  //   console.log(tickets[idOfTicket], "tickets.id")
  //   console.log(filesUrl + 'URLS')
  //   const url = filesUrl.map(item => URL.createObjectURL(item))
  //   console.log(url)
  //   const newTicket = tickets[idOfTicket].attributes.attachments.push(...url)

  //   setTickets(newTicket)
  // };

  const updateTicket = (ticket) => {
    // const index = tickets.findIndex(ticket => ticket.id === updatedTicket);
    // const urls = [];
    // console.log(tickets)
    // for (let i = 0; i < filesUrl.length; i++) {
    //   const file = filesUrl[i];
    //   const url = URL.createObjectURL(file);
    //   urls.push({ id: tickets[index].attributes?.attachments?.length + i, url: url });
    // }
    // console.log(urls)
    // if (index !== -1) {
    //   const updatedAttachments = [
    //     ...tickets[index].attributes.attachments,
    //     ...urls
    //   ];

    //   const updatedTicketWithAttachments = {
    //     ...tickets[index],
    //     attributes: {
    //       ...tickets[index].attributes,
    //       attachments: updatedAttachments
    //     }
    //   };

    //   const newTickets = [...tickets];
    //   newTickets[index] = updatedTicketWithAttachments;
    //   console.log(newTickets)
    //   setSelectedTicket(newTickets[index])
    // setTickets(newTickets);
    // } else {
    //   console.error("Ticket not found");
    // }

    setSelectedTicket(ticket);
    setTickets((prev) => {
      return prev.map((item) => {
        if (item.id === ticket.id) {
          return ticket;
        } else {
          return item;
        }
      });
    });
  };
const [isHoveredUndo, setIsHoveredUndo] = useState(false);

  const attributesHandler = (e) => {
    const { name, value } = e.target;

    setAttributes((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // console.log(companyDetails, "companyDetails")
  const [transitionStates, setTransitionStates] = useState([]);

  useEffect(() => {

    if (crm.workflow[0]) {

      const data = crm.workflow[0]?.workflow;
      setTransitionStates(
        data
          .find(
            (workflow) => workflow.workflow_config.id === activeComponent
          )
          ?.states.map((item) => item.title)
      );

      setWorkflows(data);
    }

    // if (companyDetails?.list) {
    //   const fetchWorkflows = async () => {
    //     try {
    //       // Fetch workflows data from backend API
    //       // if (companyDetails?.list?.id) {
    //       const response = await fetch(
    //         `${getBaseUrl()}/api/crm/workflows/${companyDetails?.list?.id}/`
    //       );

    //       const data = await response.json();

    //       setTransitionStates(
    //         data
    //           .find(
    //             (workflow) => workflow.workflow_config.id === activeComponent
    //           )
    //           ?.states.map((item) => item.title)
    //       );

    //       dispatch(actionsCreator.SET_WORKFLOW_CONFIGS({ workflow: data }));

    //       setWorkflows(data);
    //       // }
    //     } catch (error) {
    //       console.error("Error fetching workflows:", error);
    //     } finally {
    //       setLoading(false);
    //     }
    //   };

    //   fetchWorkflows();
    // }

  }, [companyDetails, crm]);

  useEffect(() => {

    const fetchTickets = async () => {
      if (activeComponent === 0) return;

      setLoading(true);
      try {
        // Fetch tickets data from backend API
        const response = await fetch(
          `${getBaseUrl()}/api/crm/workflows/${activeComponent}/tickets/`
        );
        const data = await response.json();
        console.log(data, "data received")

        dispatch(actionsCreator.SET_ALL_TICKETS(data));
        setTickets(data);
        setWorkflowTickets(data);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }

      setLoading(false);
    };

    fetchTickets();
  }, [activeComponent]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const openForm = () => {
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const openProductForm = (ticket) => {
    setTicketId(ticket.id);
    setTicketTitle(ticket.title);
    setTicketDescription(ticket.description);
    setTicketType(ticket.ticket_type);
    setTicketTenant(ticket.tenant);
    setShowProductForm(true);
  };

  const closeProductForm = () => {
    setShowProductForm(false);
  };

  const openAttributeForm = (ticket) => {
    setSelectedTicket(ticket);
    setSelectedTicketAttributes(ticket.attributes);
    setShowAttributeForm(true);
  };

  const closeAttributeForm = () => {
    setShowAttributeForm(false);
  };

  const handleDragStart = (e, ticket) => {
    setDraggedItem(ticket);
    if ("touches" in e) {
      e.preventDefault();
    }
    // e.target.addEventListener('mousemove', handleMouseMove);
  };

  // const handleMouseMove = (e) => {
  //   const draggedCard = document.querySelector('.dragging');
  //   if (draggedCard) {
  //     const xOffset = e.clientX - draggedCard.getBoundingClientRect().left;
  //     const yOffset = e.clientY - draggedCard.getBoundingClientRect().top;
  //     draggedCard.style.transform = `translate(${xOffset}px, ${yOffset}px) rotate(-3deg) scale(1.05)`; // Apply transform based on mouse position
  //   }
  // };

  // const handleDragEnd = () => {
  //   setDraggedItem(null);
  //   const draggedCard = document.querySelector('.dragging');
  //   if (draggedCard) {
  //     draggedCard.style.transform = ''; // Reset transform style
  //     draggedCard.removeEventListener('mousemove', handleMouseMove); // Remove mousemove event listener
  //   }
  // };

  const handleDocChange = (e) => {
    const data = e.target.value;

    changeDoctors(data)
      .then((res) => {
        console.log("changed");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(()=>{
    fetchInvoiceStatuses();
  },[])

  const handleAssignedToChange = (event, ticketId) => {
    const selectedUserId = event.target.value;
    const updatedTickets = tickets.map((ticket) => {
      if (ticket.id === ticketId) {
        return { ...ticket, assigned_to: selectedUserId };
      }
      return ticket;
    });

    setTickets(updatedTickets);

    // const data = { ticketId, assignedUserId: selectedUserId };
    // updateTicketAssignment(data).then(response => {
    //   console.log('Ticket assignment updated successfully:', response);
    // }).catch(error => {
    //   console.error('Error updating ticket assignment:', error);
    // });
  };
  
  // const [paymentStatusOptions, setPaymentStatusOptions] = useState([]);
  
  // useEffect(() => {
    //   const fetchPaymentStatusOptions = async () => {
      //     try {
        //       const response = await fetch('');
        //       if (!response.ok) {
          //         throw new Error('Failed to fetch payment status options');
          //       }
          //       const data = await response.json();
          //       setPaymentStatusOptions(data);
          //     } catch (error) {
            //       console.error('Error fetching payment status options:', error);
            //     }
            //   };

  //   fetchPaymentStatusOptions();
  // }, []);
  
  // const handlePaymentStatusChange = (e, ticketId) => {
    //   const selectedPaymentStatus = e.target.value;
    //   // Update the ticket's payment status locally
    //   const updatedTickets = tickets.map((ticket) => {
      //     if (ticket.id === ticketId) {
        //       return { ...ticket, payment_status: selectedPaymentStatus };
        //     }
        //     return ticket;
        //   });
        //   setTickets(updatedTickets);
        
        //   // const data = { ticketId, paymentStatus: selectedPaymentStatus };
        const uniqueTickets = Array.from(new Set((archivedTickets || []).map(ticket => ticket.id)))
  .map(id => {
    return (archivedTickets || []).find(ticket => ticket.id === id);
  });
        //   // updatePaymentStatus(data).then(response => {
          //   //   console.log('Payment status updated successfully:', response);
          //   // }).catch(error => {
            //   //   console.error('Error updating payment status:', error);
            //   // });
            // };
            
            useEffect(() => {
              // console.log(crm.workflow[0]);
              // console.log(typeof activeComponent + "active component");
              // console.log(
                //   typeof crm?.workflow[0]?.workflow[0]?.workflow_config?.id +
                //     "saare workflow"
                // );
                
                crm.workflow[0]?.workflow.map((workflow) => {
      if (workflow.workflow_config?.id === activeComponent) {
        setTicketLayout(workflow.workflow_config?.ticket_layout);
      }
    });
    
    // console.log(ticketLayout, "ticketLayout");
    // console.log(crm.workflow[activeComponent], "workflow component");
  }, [activeComponent, crm]);
  
  const handleTouchStart = (e, ticket) => {
    handleDragStart(e, ticket);
  };
  
  const handleTouchMove = (e) => {
    e.preventDefault();
  };
  
  const handleDragOver = (e, target) => {
    e.preventDefault();
    
    // Check if the target column is valid (present in next_states of the dragged ticket)
    if (draggedItem && draggedItem.next_states.includes(target)) {
      e.dataTransfer.dropEffect = "move"; // Allow dropping
    } else {
      e.dataTransfer.dropEffect = "none"; // Disable dropping
    }
  };
  
  const getNextTransition = (currTransition) => {
    const currInd = transitionStates.findIndex(
      (element) => element === currTransition
    );

    return transitionStates[(currInd + 1) % transitionStates.length];
  };
  
  const updateNextState = (currState, nextStates) => {
    // const
    const nextState = getNextTransition(currState);
    nextStates = [ nextState, ...nextStates];
    
    return [nextState];
  };
  
  const [draggedData, setDraggedData] = useState(null);
  useEffect(async () => {
    
    if (draggedData) {
      
      const { target } = draggedData;
      
      const prevStateItem = draggedData;
      
      const { id, current_state, next_states } = draggedData;
      
      
      if (next_states.includes(target)) {
        
        
        draggedData["current_state"] = target;
        draggedData["prev_states"] = [
          current_state,
          ...draggedData["prev_states"],
        ];
        
        
        const ticketInd = tickets.findIndex((item) => item.id === id);
        
        if (ticketInd >= 0) {
          
          const newTicket = [...tickets];
          
          
          setTickets(prevTickets => {
            const newTicket = [...prevTickets];
            newTicket[ticketInd] = draggedData;
            return newTicket;
          });
          
          
          const findIndex = (activeComponent) => {
            for (let i = 0; i < workflows.length; i++) {
              if (workflows[i].workflow_config.id === activeComponent) {
                return i;
              }
            }
            
            return;
          };

          const index = findIndex(activeComponent);
          
          const data = {
            ticket_id: id,
            next_state_id: workflows[index].states.find(
              (state) => state.title === target
            )?.id,
          };

          await ticketTransition(data)
            .then((res) => {
              
              dispatch(actionsCreator.SET_ALL_TICKETS(newTicket));
              
              setTickets(prevTickets => {
                const newTicket = [...prevTickets];
                
                draggedData["next_states"] = res.data.next_states;
                
                console.log("after Response >> ", draggedData)
                newTicket[ticketInd] = draggedData;
                return newTicket;
              });
              
            })
            .catch((err) => {
              console.log(err.message);
              
              setTickets(prevTickets => {
                const newTicket = [...prevTickets];
                newTicket[ticketInd] = prevStateItem;
                return newTicket;
              });
              
            });
        }
      } else {
        toast.info(`Ticket cannot be moved to ${target}`);
      }

    }

    // }
    
    // updateItem();
  }, [draggedData]);
  
  // const printTicket = () => {
    //   console.log('ticket Printed', workflowTickets);
    // }
    
    const handleDrop = (result, target) => {
      const draggedItem = result.data;
      
      if(draggedItem.current_state == target){
        return;
      }
      
      toggleActiveMoveBtn('');
      
      setDraggedData({ ...draggedItem, target });
    };
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      formData["channel"] = "WEBSITE";
      formData["workflow_Config"] = activeComponent;
      formData["tenant"] = companyDetails?.list?.id;
      formData["attributes"] = attributes;
      formData["title"] = attributes["name"];
      
      await createTicket(formData, activeComponent)
      .then((res) => {
        const data = res.data;
        console.log("creating new Ticket");
        toast.success("Ticket Created");
        setTickets((prevData) => [data, ...prevData]);
      })
      .catch((err) => {});
      
      closeForm();
    };
    
    const copyHandler = (text) => {
      navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Text copied successfully");
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
      });
  };
  
  const handleDelete = (ticketId) => {
    setTickets(prev => prev.filter(item => item.id !== ticketId));
  }
  console.log(workflows,"workflows");
  
  const [activeMoveBtn, toggleActiveMoveBtn] = useState('');
  const sortedTickets = uniqueTickets.sort((a, b) => new Date(a.created_on) - new Date(b.created_on));
  
  const [searchQuery, setSearchQuery] = useState('');
  
  const filteredTickets = sortedTickets.filter(ticket =>
    (ticket.ticket_id && ticket.ticket_id.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (ticket.customer_name && ticket.customer_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (ticket.description && ticket.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );
  
  
  const invoiceStatusChangeHandler =  (e, url, ticketId)=>{
    try {
      console.log(e.target.value, ticketId, "ticket id ", "value");
      const res =  edit_invoice_status( {
        "order_id": Number.parseInt(url.split("/").pop()),
        "status": e.target.value
      }).then((res)=>{
        setTickets((prev) => {
          console.log('Previous tickets state:', prev);
          const updatedTickets = prev.map((ticket) => {
            if (ticket.id === ticketId) {
              console.log('Updating ticket with ID:', ticketId);
              return {
                ...ticket,
                invoice: {
                  ...ticket.invoice,
                  invoice_status: e.target.value,
                },
              };
            } else {
              return ticket;
            }
          });
          console.log('Updated tickets state:', updatedTickets);
          return updatedTickets;
        });
      }).catch((err)=> console.log(err))
      
      
    } catch (error) {
      console.error(error)
    }
  }


  console.log(tickets,"tickets");
  const [isHovered, setIsHovered] = useState(false);
  
  const [isHoveredArchive, setIsHoveredArchive] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false); 

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showArchivedTicketsModal, setShowArchivedTicketsModal] = useState(false);

  const TicketManagement = ({ crm, workflows, activeComponent }) => {
    const [tickets, setTickets] = useState([
    
    ]);
  }


  useEffect(() => {
    const savedArchivedTickets = localStorage.getItem('archivedTickets');
    if (savedArchivedTickets) {
      setArchivedTickets(JSON.parse(savedArchivedTickets));
    } else {
      setArchivedTickets([]); 
    }
  }, []);
  
  // Store archived tickets in localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('archivedTickets', JSON.stringify(archivedTickets));
  }, [archivedTickets]);
  
  const handleConfirmArchive = async () => {
    if (!selectedTicket || !selectedTicket.id) {
      console.error("Ticket is not defined or missing ID");
      return;
    }
  
    setIsArchiving(true);
  
    try {
      // Archive the ticket with "ARCHIVED" status
      const response = await archiveTicket(selectedTicket.id, "ARCHIVED");
      
      console.log("Archive response:", response); // Debugging line
  
      if (response.status === 200) {
        console.log("Ticket archived successfully");
  
        const updatedTicket = { 
          ...selectedTicket, 
          status: "ARCHIVED", 
          originalStatus: selectedTicket.status 
        };
  
        const updatedArchivedTickets = [...archivedTickets, updatedTicket];
        setArchivedTickets(updatedArchivedTickets);
  
        setTickets(tickets.filter(ticket => ticket.id !== selectedTicket.id));
  
        setIsModalOpen(false);
        setShowArchivedTicketsModal(false);
        toast.success(`Ticket archived successfully (ID: ${selectedTicket.ticket_id})`);
  
      } else {
        console.error("Failed to archive ticket. Status code:", response.status);
      }
    } catch (error) {
      console.error("Error archiving ticket:", error);
    } finally {
      setIsArchiving(false);
    }
  };
  
  
  const handleUndoArchive = async (ticket) => {
    if (!ticket || !ticket.id) {
      console.error("Ticket is not defined or missing ID");
      return;
    }
  
    setIsArchiving(true);
  
    try {
      // Unarchive the ticket
      const response = await archiveTicket(ticket.id, ticket.originalStatus); // Restore original status
  
      if (response.status === 200) {
        console.log("Undo archive successful");
  
        // Update the ticket's status back to its original status
        const updatedTicket = { ...ticket, status: ticket.originalStatus };
  
        // Add back to active tickets
        setTickets(prevTickets => [...prevTickets, updatedTicket]);
  
        // Remove from archived tickets
        setArchivedTickets(prevArchived => 
          prevArchived.filter(archivedTicket => archivedTicket.id !== ticket.id)
        );
  
        setIsModalOpen(false);
        setShowArchivedTicketsModal(true);
        setUndoVisible(false);
        toast.success(`Ticket Unarchived successfully (ID: ${selectedTicket.ticket_id})`);
      } else {
        console.error("Failed to undo archive");
      }
    } catch (error) {
      console.error("Error undoing archive:", error);
    } finally {
      setIsArchiving(false);
    }
  };


  
  const openArchivedTicketsModal = () => {
    setShowArchivedTicketsModal(true);
  };
  
  const closeArchivedTicketsModal = () => {
    setShowArchivedTicketsModal(false);
  };
  
  // const openForm = () => setShowForm(true);
  // const closeForm = () => setShowForm(false);

  // const openArchivedTicketsModal = () => setShowArchivedTicketsModal(true);
  // const closeArchivedTicketsModal = () => setShowArchivedTicketsModal(false);


  
  const handleConfirm = () => {
    archiveTicket();
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const [ticket, setTicket] = useState({ id: 341 })
  const formContainerStyle = {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    border: '2px solid #ddd',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '30px',
    width: '100%',
    maxWidth: '550px',
    margin: '0 auto',
    // boxShadow: 'none', // Uncomment this if you want to remove the box-shadow
  };

  const [recentlyArchivedTicket, setRecentlyArchivedTicket] = useState(null);
  const [undoVisible, setUndoVisible] = useState(false);
  useEffect(() => {
    if (showArchivedTicketsModal) {
      setSearchQuery(''); // Clear search input when modal is opened
    }
  }, [showArchivedTicketsModal]);

  return (
<div 
  className="h-full w-full"
  style={{
    overflowX: 'hidden',
  }}
>
  {loading === true ? (
    <div className="w-full h-full flex place-item-center">
      <Loader />
    </div>
  ) : (
    <div className="">
      <div className="">
        <button
          onClick={openForm}
          type="button"
          className="btn btn-outline-light p-2 px-3 rounded-pill text-white create-ticket-button"
          style={{ backgroundColor: "#60E1CB" }}
        >
          Create {`${crm.workflow[0].workflow.filter((workflow)=> workflow.workflow_config?.id === activeComponent)[0]?.workflow_config?.title}`}
        </button>

        <Modal show={showForm} onClose={closeForm}>
          <form
            onSubmit={(event) => handleSubmit(event)} style={formContainerStyle}
            className="form-container create-ticket-form-wrapper"
          >
            <h2 className="create-ticket-heading">Ticket Details</h2>
            <div className="form-group">
              <label htmlFor="name">Title</label>
              <input
                type="text"
                name="customer_name"
                id="name"
                value={formData?.customer_name}
                onChange={changeHandler}
              />
            </div>
            <div className="form-group">
              <label htmlFor="detail">Description</label>
              <input
                type="text"
                id="detail"
                name="description"
                required
                value={formData?.description}
                onChange={changeHandler}
              />
            </div>
            <div className="form-buttons-container">
              <button
                type="button"
                className="create-ticket-submit-button"
                onClick={closeForm}
              >
                Go Back
              </button>
              <button type="submit" className="create-ticket-submit-button">
                Submit
              </button>
            </div>
          </form>
        </Modal>
      </div>

      <div className="top-div">
  <button
    onClick={openArchivedTicketsModal}
    type="button"
    className="btn btn-custom"
  >
    View Archived Tickets <IoArchive style={{ marginLeft: '8px' }} />
  </button>

  {workflows
    .find((workflow) => workflow.workflow_config.id === activeComponent)
    ?.states?.sort((a, b) => a.priority - b.priority)
    .map((state, stateIndex) => (
      <DroppableDiv
        key={stateIndex}
        className="columns relative"
        handleDrop={(result) => handleDrop(result, state.title)}
        title={state.title}
      >
        <div
          className=""
          style={{
            width: '100%',
            position: 'sticky',
            top: '0',
            left: '0',
            zIndex: '100',
            marginTop: '5px',
          }}
        >
          <div className="column-head">{state.title}</div>
        </div>

        {tickets?.filter((ticket) => ticket.current_state === state.title).length === 0 && (
          <p className="text-center bold-lg text-sm" style={{ marginTop: '1rem' }}>
            No Tickets to show...
          </p>
        )}

{tickets
  .filter((ticket) => {
    // Check if the ticket is archived
    const isArchived = archivedTickets.some(
      (archivedTicket) => archivedTicket.id === ticket.id
    );
    return !isArchived && ticket.current_state === state.title;
  })
  // Remove duplicates by ticket_id
  .reduce((uniqueTickets, currentTicket) => {
    if (!uniqueTickets.some((ticket) => ticket.ticket_id === currentTicket.ticket_id)) {
      uniqueTickets.push(currentTicket);
    }
    return uniqueTickets;
  }, [])
  .sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
  .map((ticket, ticketIndex) => (
    <DraggableDiv key={ticketIndex} className="card" data={ticket}>
      <div className="card-header">
        <span>{ticket.ticket_id}</span>
        <div className="flex flex-row items-center gap-10">
          {/* Eye Button */}
          <div
            className="eye-icon-wrapper"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <IoEye
              color="green"
              size={20}
              onClick={() => openAttributeForm(ticket)}
              style={{ cursor: 'pointer' }}
            />
            {isHovered && <div className="floating-alert">Ticket</div>}
          </div>

          {/* Archive Button */}
          <div
            className="archive-icon-wrapper"
            onMouseEnter={() => setIsHoveredArchive(true)}
            onMouseLeave={() => setIsHoveredArchive(false)}
          >
            <IoArchive
              color={isArchiving ? 'grey' : 'green'}
              size={20}
              onClick={() => {
                setSelectedTicket(ticket); // Save the ticket to archive
                setIsModalOpen(true); // Open the confirmation modal
              }}
              style={{ cursor: isArchiving ? 'not-allowed' : 'pointer' }}
              disabled={isArchiving}
            />
            {isHoveredArchive && <div className="floating-alert">Archive</div>}
          </div>

          {/* Move Button */}
          <MoveButton
            activeMoveBtn={activeMoveBtn}
            toggleActiveMoveBtn={toggleActiveMoveBtn}
            ticket={ticket}
            handleDrop={handleDrop}
          />
        </div>
      </div>

      {/* Ticket details */}
      <div className="heading-ticket">Title: {ticket.customer_name}</div>
      <div className="description-ticket">Desc: {ticket.description}</div>

      {/* Challan URL */}
      {ticket?.order_items?.[0]?.order_id != null &&
        String(ticket.order_items[0].order_id).trim() !== '' &&
        ticket?.challan_url &&
        ticket.challan_url.trim() &&
        ticket.challan_url !== 'https://incitoindia.com/challan/' &&
        !ticket.challan_url.endsWith('null') && (
          <div className="ticket-invoice-wrapper">
            <p>
              <b>Challan Url:</b>
              <div className="assigned_name">
                <div className="invoice-url">{ticket.challan_url}</div>
                <div>
                  <FaCopy
                    onClick={() => copyHandler(ticket.challan_url)}
                    className="copy-icon"
                  />
                </div>
              </div>
            </p>
          </div>
        )}

      {/* Invoice URL and Status */}
      {ticket?.invoice?.invoice_url && ticket?.invoice?.invoice_url.trim() && (
        <div className="ticket-invoice-div-wrapper">
          <div className="ticket-invoice-wrapper">
            <p>
              <b>Invoice Url:</b>
              <div className="assigned_name">
                <div className="invoice-url">{ticket?.invoice?.invoice_url}</div>
                <div>
                  <FaCopy
                    onClick={() => copyHandler(ticket?.invoice?.invoice_url)}
                    className="copy-icon"
                  />
                </div>
              </div>
            </p>
          </div>

          <div className="invoice-status">
            <div className="invoice-status-options">
              Invoice status:
              <select
                value={ticket?.invoice?.invoice_status}
                onChange={(e) =>
                  invoiceStatusChangeHandler(
                    e,
                    ticket?.invoice?.invoice_url,
                    ticket?.id
                  )
                }
              >
                {invoice_statuses &&
                  Object.keys(invoice_statuses).map((key) => (
                    <option value={key} key={key}>
                      {invoice_statuses[key]}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      )}

      <div className="author">
        <div className="created-on">{formatDate(ticket.created_on)}</div>
        <div className="status">
          {ticket.status}
          <span className="mx-2">{ticket.channel}</span>
        </div>
      </div>
    </DraggableDiv>
  ))}

      </DroppableDiv>
    ))}

  {/* Confirmation Modal outside of the ticket loop */}
  {isModalOpen && (
    <Modal show={isModalOpen} onClose={handleClose} title="Confirm Archive">
      <div>
        <p>Are you sure you want to archive this ticket?</p>
        <button
          onClick={handleConfirmArchive}
          disabled={isArchiving}
          className={`confirm-button ${isArchiving ? 'disabled' : ''}`}
        >
          Yes
        </button>
        <button
          onClick={handleClose}
          disabled={isArchiving}
          className={`cancel-button ${isArchiving ? 'disabled' : ''}`}
        >
          No
        </button>
      </div>
    </Modal>
  )}
</div>


      {showProductForm && (
        <AddSellableInventoryModal
          closeModal={closeProductForm}
          ticketId={ticketId}
          ticketTitle={ticketTitle}
          ticketDescription={ticketDescription}
          tickets={tickets}
          setTickets={setTickets}
          workflows={workflows}
        />
      )}

      {showAttributeForm && (
        <Attribute
          fileUrls={fileUrls}
          setFileUrls={setFileUrls}
          attributes={selectedTicketAttributes}
          setSelectedTicket={setSelectedTicket}
          closeModal={closeAttributeForm}
          tickets={selectedTicket}
          ticketId={selectedTicket.id}
          allTickets={tickets}
          setAllTickets={setTickets}
          updateTicket={updateTicket}
          comments={comments}
          onAddComment={handleAddComment}
          setTickets={setTickets}
          ticketLayout={ticketLayout}
        />
      )}

      {/* Archived Tickets Modal */}
       
      <Modal
  show={showArchivedTicketsModal}
  onClose={closeArchivedTicketsModal}
  title="Archived Tickets"
>
  <div className="archived-tickets-form">
    <div className="archived-tickets-form-header">
      Archived Tickets
    </div>

    {/* Redesigned Search Bar */}
    <div className="search-bar-wrapper">
      <input
        type="text"
        placeholder="Search by Ticket ID, Title, or Description"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="ticket-search-input"
      />
    </div>

    {filteredTickets.length > 0 ? (
      <div className="archived-tickets-container">
        {filteredTickets.map((ticket) => (
          <div key={ticket.id} className="card archived-ticket-card">
            <div className="card-header">
              <span>{ticket.ticket_id}</span>
              <div className="flex flex-row items-center gap-10">
                <div
                  className="eye-icon-wrapper"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <IoEye
                    color="green"
                    size={20}
                    onClick={() => {
                      openAttributeForm(ticket);
                      closeArchivedTicketsModal(); // Close the modal when clicked
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  {isHovered && <div className="floating-alert">Ticket</div>}
                </div>

                <div
                  className="undo-icon-wrapper"
                  onMouseEnter={() => setIsHoveredUndo(true)}
                  onMouseLeave={() => setIsHoveredUndo(false)}
                >
                 <IoArrowUndo
                   color="blue"
                   size={20}
                   onClick={() => handleUndoArchive(ticket)} 
                   style={{ cursor: "pointer" }}
                 />
                  {isHoveredUndo && <div className="floating-alert">Undo Archive</div>}
                </div>
              </div>
            </div>

            <div className="heading-ticket"> Title: {ticket.customer_name} </div>
            <div className="description-ticket"> Desc: {ticket.description} </div>

            {/* Challan URL */}
            {ticket?.order_items?.[0]?.order_id != null && 
              (String(ticket.order_items[0].order_id).trim() !== '') &&
              ticket?.challan_url && 
              ticket.challan_url.trim() && 
              ticket.challan_url !== 'https://incitoindia.com/challan/' && 
              !ticket.challan_url.endsWith('null') && (
              <div className="ticket-invoice-wrapper">
                <p>
                  <b>Challan Url:</b>
                  <div className="assigned_name">
                    <div className="invoice-url">{ticket.challan_url}</div>
                    <div>
                      <FaCopy onClick={() => copyHandler(ticket.challan_url)} className="copy-icon" />
                    </div>
                  </div>
                </p>
              </div>
            )}

            {/* Invoice URL and Status */}
            {ticket?.invoice?.invoice_url && ticket?.invoice?.invoice_url.trim() && (
              <div className="ticket-invoice-div-wrapper">
                <div className="ticket-invoice-wrapper">
                  <p>
                    <b>Invoice Url:</b>
                    <div className="assigned_name">
                      <div className="invoice-url">{ticket?.invoice?.invoice_url}</div>
                      <div>
                        <FaCopy onClick={() => copyHandler(ticket?.invoice?.invoice_url)} className="copy-icon" />
                      </div>
                    </div>
                  </p>
                </div>

                <div className="invoice-status">
                  <div className="invoice-status-options">
                    Invoice status:
                    <select
                      value={ticket?.invoice?.invoice_status}
                      onChange={(e) => invoiceStatusChangeHandler(e, ticket?.invoice?.invoice_url, ticket?.id)}
                    >
                      {invoice_statuses && Object.keys(invoice_statuses).map((key) => (
                        <option value={key} key={key}>{invoice_statuses[key]}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}

            <div className="author">
              <div className="created-on">{formatDate(ticket.created_on)}</div>
              <div className="status">
                {ticket.status} <span className="mx-2">{ticket.channel}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <p className="no-tickets-message">No archived tickets available now.</p>
    )}
  </div>
</Modal>




    </div>
  )}
</div>

  );
};

export default Ticket;
