import React, { useEffect, useState } from 'react';
import { AddTicketFields } from '../../../../Api/crmApi';
import './Field.scss';
import { toast } from 'react-toastify';
import { getBaseUrl } from '../../../../Lib/NetworkHandler';
import axios from 'axios';

const Field = ({ selectedTicket, setTickets, ticketLayout }) => {
    const [inputValues, setInputValues] = useState({});
    const [entity, setEntity] = useState("");
    const [showEntitySearch, setShowEntitySearch] = useState(false);
    const [filteredEntities, setFilteredEntities] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(null);

    // Function to fetch entity details by ID
    const fetchEntityById = async (entityId) => {
        try {
            const baseurl = getBaseUrl();
            const { data } = await axios.get(`${baseurl}/api/shop/entities/${entityId}/`);
            setSelectedEntity(data);
            setEntity(data.title);
        } catch (err) {
            console.error("Error fetching entity details:", err);
        }
    };

    useEffect(() => {
        if (selectedTicket?.attributes?.ticket_fields) {
            const ticketFields = selectedTicket.attributes.ticket_fields;
            const newInputValues = {};
            ticketLayout?.input_fields?.forEach(field => {
                newInputValues[field?.key] = ticketFields[field?.key] || '';
            });
            setInputValues(newInputValues);

            // Check if entity_id is present in ticket fields
            if (ticketFields.entity_id) {
                fetchEntityById(ticketFields.entity_id);
            } else if (ticketFields.entity_title) {
                // If entity_id is not present but entity_title is, set entity title
                setEntity(ticketFields.entity_title);
            }
        }
    }, [selectedTicket, ticketLayout]);

    const handleInputChange = (e, fieldKey) => {
        const { value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [fieldKey]: value
        }));
    };

    const handleEntitySearch = async (e) => {
        try {
            setEntity(e.target.value);
            setShowEntitySearch(true);
            const baseurl = getBaseUrl();
            const { data } = await axios.get(`${baseurl}/api/shop/entities/?searchKey=${e.target.value}`);
            setFilteredEntities(data);
        } catch (err) {
            console.error("Error fetching entities:", err);
        }
    };

    const handleSave = async () => {
        if (!selectedEntity?.id) {
            toast.error('Entity ID is required');
            return;
        }

        const inputData = [];

        ticketLayout?.input_fields?.forEach(field => {
            const inputField = {
                key: field.key,
                value: inputValues[field.key] || ''
            };
            inputData.push(inputField);
        });

        // Add entity details to inputData
        inputData.push(
            { key: 'entity_id', value: selectedEntity?.id || '' },
            { key: 'entity_title', value: selectedEntity?.title || '' },
            { key: 'entity_address', value: selectedEntity?.address || '' },
            { key: 'entity_dl_no', value: selectedEntity?.dl_no || '' },
            { key: 'entity_gstin', value: selectedEntity?.gstin || '' },
            { key: 'entity_phone_number', value: selectedEntity?.phone_number || '' }
        );

        const payload = {
            inputs: inputData,
            ticket_id: selectedTicket.id
        };

        try {
            const response = await AddTicketFields(payload);
            toast.success('Saved successfully');
            const ticket = response.data.ticket;
            setTickets((prev) =>
                prev.map((item) => (item.id === ticket.id ? ticket : item))
            );
        } catch (error) {
            toast.error('Failed to save. Please try again.');
            console.error(error);
        }
    };

    return (
        <div className="ticket-form space-y-10">
            <div>
                <div className='space-y-10 left-part'>
                    <p>Surgery Details:</p>
                    {ticketLayout?.input_fields?.map((field, index) => (
                        field.key !== "" && (
                            <div key={index} className="assigned_wrapper">
                                <label>{field.key}:</label>
                                <input
                                    type={
                                        field?.field_type === "input/text"
                                            ? "text"
                                            : field?.field_type === "input/date"
                                            ? 'date'
                                            : field?.field_type === "input/datetime-local"
                                            ? 'datetime-local'
                                            : 'text'
                                    }
                                    value={inputValues[field.key] || ''}
                                    onChange={(e) => handleInputChange(e, field.key)}
                                    className="mx-2 fields-input"
                                />
                            </div>
                        )
                    ))}
                </div>
                <div className='right-part'>
                    <p>Entity Details:</p>
                    <div className='assigned_wrapper'>
                        <label style={{ fontSize: "19px", marginBottom: "1px" }}>Entity</label>
                        <input
                            type="text"
                            placeholder='Enter Entity'
                            value={entity}
                            onChange={(e) => handleEntitySearch(e)}
                        />
                        {showEntitySearch && entity.length > 0 &&
                            <ul>
                                {filteredEntities.map((entityItem, index) => (
                                    <li
                                        key={index}
                                        onClick={() => {
                                            setEntity(entityItem.title);
                                            setShowEntitySearch(false);
                                            setSelectedEntity(entityItem);
                                        }}
                                    >
                                        {entityItem.title}
                                    </li>
                                ))}
                            </ul>
                        }
                        {selectedEntity && entity && entity === selectedEntity?.title && (
                            <div className='entity-card'>
                                <div>
                                    <p><span>Hospital Name:</span> {selectedEntity?.title || ""}</p>
                                    <p><span>Hospital Address:</span> {selectedEntity?.address || ""}</p>
                                    <p><span>Hospital DL No:</span> {selectedEntity?.dl_no || ""}</p>
                                    <p><span>Hospital GSTIN:</span> {selectedEntity?.gstin || ""}</p>
                                    <p><span>Hospital Phone No:</span> {selectedEntity?.phone_number || ""}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <button onClick={handleSave} className="field-save-button">Save</button>
        </div>
    );
};

export default Field;
