import React from 'react'
import './WhyShop.scss'
import '../WhyShopCard/WhyShopCard'
import WhyShopCard from '../WhyShopCard/WhyShopCard';
import feature1Icon from '../../Assets/Images/why shop with us/icon/icon1.svg';
import feature2Icon from '../../Assets/Images/why shop with us/icon/icon2.svg';
import feature3Icon from '../../Assets/Images/why shop with us/icon/icon3.svg';
import feature4Icon from '../../Assets/Images/why shop with us/icon/icon4.png';
import whyShopImage from '../../Assets/Images/why shop with us/icon/whyuseous.svg';

import WhyShopBottomContainer from './BottomContainer/WhyShopBottomContainer';

const WhyShop = () => {
    return (
        <>
            <div className='whyshop-wrapper'>
                <div className='whyshop-container'>
                    <div className='whyshop-title'>
                        <h2>Why should you use Us?</h2>
                        <p>We always provide the best and fastest service for All Our Customers</p>
                    </div>
                    <WhyShopCard
                        text="Get started in a few clicks with minimal documentation."
                        image={feature1Icon} />
                    <WhyShopCard
                        text="We use AI to custom build your solution."
                        image={feature2Icon} />
                    <WhyShopCard
                        text="Add Services as you grow your business."
                        image={feature3Icon} />
                    <WhyShopCard
                        text="Get powerful Analytics in your dashboard."
                        image={feature4Icon} />
                </div>
                <div className="whyshop-image-container">
                    <img src={whyShopImage} alt="" className="whyshop-image" />
                </div>
            </div >
            <WhyShopBottomContainer />

        </>
    )
}

export default WhyShop
