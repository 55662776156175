import React from "react";
import HoverComponent from "../HoverComponent/HoverComponent";
import InfiniteScroller from "../InfiniteScrollContainer/InfiniteScrollerContainer";

const SearchSuggestion = (
    {
        children,
        hoverRef,
        style,
        hasMore, 
        fetchMore,
        cardType
    }
    ) => {
  return (
    <HoverComponent
      hoverRef={hoverRef}
      style={{
        height: "50vh",
        ...style,
        backgroundColor: "white",
        border: "1px solid #f2f2f2",
        overflowY: "scroll",
        overflowX: "hidden",
        padding: "5px",
      }}
    >
        {
            !hasMore &&
            <div className="flex-row w-full justify-center items-center">
                No items to show
            </div>
        }
        <div className={`${cardType} w-full h-full`}>
          {/* <InfiniteScroller
            hasMore={hasMore}
            fetchMore={fetchMore}
            errorMsg={"No items to show"}
          > */}
            {
                children
            }
          {/* </InfiniteScroller> */}
        </div>
    </HoverComponent>
  );
};

export default SearchSuggestion;
