import React from "react";
import "./Footer.scss";

const Footer = () => {
  const current_year = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="footer-content-wrapper">
          <div className="footer-content footer-aboutus">
            <p className="footer-head">About Us</p>
            <p>
              Just like you, we have managed our retail stores. We understand
              how difficult it is to maintain and build tech while running
              operations. That is why we present you AikTech. With Aik, you can
              build yourself custom tech solutions instantly to get started. Add
              Features as you grow your business. Billing to Mobile Application,
              we take care of everything so that you can focus on doing what you
              do best - Delight your customers and grow your Business.{" "}
            </p>
          </div>
          <div className="footer-content footer-contact footer-links">
            <div>
              <ul>
                <li>
                  <p className="footer-head">Useful Link</p>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="/privacy-policy#Aboutus"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="/privacy-policy#PrivacyPolicy"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="/privacy-policy#ContactUs"
                  >
                    Contact Information
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer-content footer-contact">
            <div>
              <ul>
                <li>
                  <p className="footer-head">Contact</p>
                </li>
                <li>
                  <a href="mailto:care@aiktech.in">
                    E-mail us at <br /> care@aiktech.in
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <p>© Aik-tech Private Limited {current_year}</p>
    </div>
  );
};

export default Footer;
