import userEvent from "@testing-library/user-event";
import React, { useEffect, useRef, useState } from "react";
import { BsArrowDown, BsArrowDownCircleFill, BsArrowUp, BsArrowUpCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addSellableInventory } from "../../../Api/catalogAPI";
import { addTenantSellableInventory } from "../../../Api/cmsAPI";

import { editTenantUsers, addTenantUser, fetchTenantProducts, fetchInventorys, editTenantSellableInventory } from "../../../Api/cmsAPI";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";
import { fetchData } from "../../../Utils/general-utils";
import LinkProduct from "../AddContent/AddCategory/LinkProduct";
import HoverComponent from "../Components/HoverComponent/HoverComponent";
import CenterModal from "../../../Components/CenterModal";
import { Modal } from "../../../Components";
import './AddSellableInventory.scss';
import { place_order, ticketTransition } from "../../../Api/crmApi";

const mapStateToProps = ({ products = {} }) => ({
  products,
})

function AddSellableInventoryModal({
  closeModal,
  inventoryForm,
  handleResponse,
  ticketId,
  ticketTitle,
  ticketDescription,
  tickets,
  setTickets,
  workflows
}) {

  const { products:
    { list: fullProductList = [],
      page: productPage = 1 }
  } = useSelector(mapStateToProps)

  const dispatch = useDispatch();

  const [item, setItem] = useState({
    id: "",
    quantity_remaining: "",
    address: "",
    expiry: "",
    batch_number: "",
    product: "",
    inventory: "",
  });

  const [inventoryData, setInventoryData] = useState([])
  const [hasMoreInventory, toggleHasMoreInventory] = useState(false);

  

  const [price, setPrice] = useState();
  const [market_price, setMarketPrice] = useState();
  useEffect(() => {
    if (inventoryForm && inventoryForm.price_variation && inventoryForm?.price_variation?.length !== 0) {
      setPrice(inventoryForm.price_variation[0].price);
      setMarketPrice(inventoryForm.price_variation[0].market_price);
    }
  }, []);

  const handleChange = (e, payload) => {
    if (e === 'product') {
      setItem((prev) => ({
        ...prev,
        ["product"]: payload
      }))

      return;
    }

    if (e === "inventory") {
      setItem((prev) => ({
        ...prev,
        ["inventory"]: payload,
      }))

      return;
    }

    setItem((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // handling linking product
  const [hasMoreProduct, toggleHasMoreProduct] = useState(true);
  const prodPage = useRef(0);
  const [products, setProducts] = useState([]);
  const [cuurentProductIndex, setCurrentProductIndex] = useState(1);

  const fetchMoreProdct = async (query) => {
    if (fullProductList.length <= 0) {
      return;
    } else {
      const size = 18;

      const items = fetchData(fullProductList, prodPage.current, query, "product_name");


      if (items.length < size) {
        toggleHasMoreProduct(false);
      }

      const uniqueData = [
        ...new Set([...products, ...items].map((item) => item.id)),
      ].map((id) => [...products, ...items].find((item) => item.id === id));

      setProducts(uniqueData);

      prodPage.current += 1;
    }

  };

  const [price_variation, setPriceVariation] = useState({});


  const [selectProductModal, toggleSelectProductModal] = useState(false);
  const [selectInventoryModal, toggleSelectInventoryModal] = useState(false);

  const selectProductRef = useRef()
  const selectInventoryRef = useRef()
  const [quantity, setQuantity] = useState(1);
  const [addedItems, setAddedItems] = useState([
    { }
  ]);

  const handleAddNewItem = (e) => {
    e.preventDefault()
    setAddedItems(prevItems => [...prevItems, { }]);
  };

  const handleItemChange = (index, key, value) => {
    setAddedItems((prevItems) => {
      return(
      prevItems.map((item, number)=>(
      number === index ? {...item, [key]:value}: item
      )
    ))
      });
  };

  const handleIncrement = () => {
    setQuantity(prevQuantity => prevQuantity + 1); // Increase quantity by 1
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1); // Decrease quantity by 1, minimum quantity should be 1
    }
  };

  const handleSubmitOrder = async (e) => {
    e.preventDefault()

    try {
      const orderData = {
        items: addedItems.map(item => ({
          product_id: item?.product?.id,
          quantity: item.quantity
        })),
        ticket: ticketId
      };

  
      place_order(orderData).then((res)=>{
        toast.success('Order placed successfully');
        const data = {
          ticket_id: ticketId,
          next_state_id:workflows[0].states.find((state)=> state.title === tickets.find((ticket)=>ticket.id === ticketId)?.next_states[0])?.id
        }
        
        ticketTransition(data).then((res)=>{
          setTickets(prevData => (
            prevData.map(item => (item.id === res.data.id ? res.data : item))
        ))
  
        }).catch((err)=>{
          console.log(err.message);
        })
  
        closeModal(false);
      })
      .catch((err)=>{
        console.log(err.message,"error while placing order");
      })

    }
    catch (error) {
      console.error('Error placing order:', error);
      toast.error('Failed to place order. Please try again later.');
    }
  };


  return (
    <Modal title={"Add Product"} show onClose={closeModal}>
      <form
        style={{
          height : '80vh'
        }}
        className="add-product-wrapper flex-column gap-10"
        encType="multipart/form-data"
      >
        <div
          className="flex-row items-center gap-10 w-full "
        >
          <div
            className="w-full relative"
            ref={selectProductRef}
          >
          <div className="ticket-details">
            <p><b>ID:</b> {ticketId}</p>
            <p><b>Title:</b> {ticketTitle}</p>
            <p className="ticket-description"><b>Description:</b> {ticketDescription}</p>
          </div>
          <div className="flex-column  w-full mt-3">


              {addedItems.map((item, index) => (
                <div className="d-flex flex-row">
                  <div
                    className="w-90 flex-row justify-between items-center"
                    style={{
                      fontSize: '0.8em',
                      fontWeight: 300,
                      border: '1px solid #d2d1d154',
                      padding: '0.5em',
                      marginBottom: '5px',
                      width: '100%'
                    }}

                    onClick={() => {
                      toggleSelectProductModal((prev) => !prev);
                      setCurrentProductIndex(index)
                    }}
                  >
                    <span className="text-uppercase">
                      {
                        item?.product?.product_name || 'Select Product'
                      }
                      
                    </span>
                    {selectProductModal ? (
                      <BsArrowUp size={"0.8rem"} color={"black"} />
                    ) : (
                      <BsArrowDown size={"0.8rem"} color={"black"} />
                    )}
                  </div>
                  <div className="flex-row gap-10 " style={{ width: '25%' }}>
                    <input
                      type="number"
                      min="1"
                      value={item.quantity}
                      onChange={(e) => handleItemChange(index, 'quantity', parseInt(e.target.value))}
                      style={{ width: '50px', borderRadius: '5px', padding: '5px', height: '35px', textAlign: 'center', backgroundColor: '#f2f2f2' }}
                      className="mx-3"
                    />
                  </div>
                </div>
              ))}
              <div className="add-new-item-btn-container my-3">
                <button className="btn-none btn-active-cms" onClick={handleAddNewItem}>
                  Add New Item
                </button>
              </div>
            </div>

            {selectProductModal && (
              <HoverComponent
                hoverRef={selectProductRef}
                style={{
                  backgroundColor: "#f2f2f2",
                  width: "100%",
                  height: "10rem",
                  whiteSpace: "normal",
                }}
              >
                <LinkProduct
                  searchField={"product_name"}
                  hasMore={hasMoreProduct}
                  toggleHasMore={toggleHasMoreProduct}
                  setPage={prodPage}
                  fullItem={fullProductList}
                  fetchMore={(query) => {
                    fetchMoreProdct(query);
                  }}
                  onSave={(data) => {
                    handleItemChange(cuurentProductIndex,"product", data[0]);
                  }}
                  closeModal={toggleSelectProductModal}
                  isMultiSelect={false}
                />
              </HoverComponent>
            )}
          </div>
        </div>

        <div
          className="flex-row justify-content-center gap-10 mt-3"
          style={{
            marginTop: "2rem 0 ",
          }}
        >
          <button
            type="button"
            className="btn-none btn-inactive-cms"
            onClick={() => closeModal(false)}
          >
            Cancel
          </button>
          <button className="btn-none btn-active-cms" type="submit" onClick={handleSubmitOrder}>
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default AddSellableInventoryModal;
