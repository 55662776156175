import React, { useEffect, useState } from 'react'
import CategoryCard from '../CategoryCard/CategoryCard'
import axios from 'axios'
import './Categories.scss'
import { useHistory } from 'react-router-dom'
import { productAPI } from '../../../Api'
import { useDispatch, useSelector } from 'react-redux'
import { actionsCreator } from '../../../Redux/actions/actionsCreator'
import Leftarrow from '../../../Assets/Icons/left-arrow.svg'
import Rightarrow from '../../../Assets/Icons/right-arrow.svg'

const mapStateToProps = ({
    categories = {}
}) => ({
    categories
});

const Categories = () => {
    const {
        categories: { list: categoryList }
    } = useSelector(mapStateToProps);
    const history = useHistory();
    const dispatch = useDispatch();
    const fetchCategories = async () => {
        dispatch(actionsCreator.FETCH_CATEGORIES());
    }

    const [categoryDetails, setCategoryDetails] = useState({
        name : categoryList[0]?.name || 'Category Name', 
        desc : categoryList[0]?.desc || `Here's our best creations that everyone loves. Lightness and
        sweetness of the cake make you want more and more. Start
        from cake, bread and other creations.`,
    })

    useEffect(() => {
        fetchCategories();
    }, [])

    const selectCategory = (name, desc) => {
        setCategoryDetails(prev => ({...prev, ['name'] : name, ['desc'] : desc}))
        history.push(`/categories/${name}`)
    }

    const findScrollVal = (direction, scrollVal, scrollWidth) => {
        let val = 0;
        if (scrollVal <= scrollWidth) {
            val = direction === "forward" ? scrollVal + 100 : scrollVal - 100;
        } else {
            val = scrollWidth;
        }
        return val < 0 ? 0 : val;
    };



    const scroll = () => {
        const buttonRight = document.getElementById('slideRight');
        const buttonLeft = document.getElementById('slideLeft');

        buttonRight.onclick = function () {
            document.getElementById('scrollDiv').scrollLeft += 120;
            console.log("SCROLL RIght")
        };
        buttonLeft.onclick = function () {
            document.getElementById('scrollDiv').scrollLeft -= 120;
            console.log("SCROLL left")
        };
    };

    return (
        <div className='category-wrapper'>

            <div className="category-name-desc">
                
                <p className="category-name">{categoryDetails?.name}</p>
                {
                    categoryDetails?.desc && 
                    <p className="category-desc">
                        {
                            categoryDetails?.desc
                        }
                    </p>
                }
            </div>

            <div className="category-container" id="scrollDiv">
                {
                    categoryList ? categoryList.map((item, index) => {
                            return( 
                                item.home_page && <CategoryCard
                                    title={item.name}
                                    key={item.name}
                                    image={item.image}
                                    onClick={() => selectCategory(item.name, item.description)}
                                    color={(index)}
                                />
                            )
                        })
                    : 
                    null
                }
            </div>
        </div >
    )
}

export default Categories

// categories and description on that page ;
// selected products of selected category ; 
// 
