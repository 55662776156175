import React, { useState, useEffect, Suspense, lazy } from "react";
import "./Builder.scss";
import LoadComponent from "./LoadComponent";
import Nav from "../../Components/Board/Nav";
import PreviewLoader from "../PreviewLoader";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const aiktechGrocery = lazy(() => import("../../Templates/aiktech-grocery/src"))
const aiktechPharmacy = lazy(() => import("../../Templates/aiktech-pharmacy/src"))
const aiktechBakery = lazy(() => import("../../Templates/aiktech-bakery/src"))
const aiktechHomeDecor = lazy(() => import("../../Templates/aiktech-home-decor/src"))
const aiktechBakeryAdvanced = lazy(() => import("../../Templates/aiktech-bakery-advanced/src"))
const aiktechInformative = lazy(() => import("../../Templates/informative/src"));

const Builder = () => {
  const { selectedTenant: tenantDetails } = useSelector((state) => state.tenantData);

  const history = useHistory();
  const { categoryId } = useLocation().state || { categoryId: 1 };
  const finalDetails = { categoryId, ...tenantDetails };

  const [components, toggleComponents] = useState([]);
  const [currComponentInd, setCurrComponentInd] = useState(0);
  const [loading, toggleLoading] = useState(true);

  useEffect(() => {

    const categoryTemplates = {
      1: import("../../Templates/aiktech-grocery/src"),
      2: import("../../Templates/aiktech-pharmacy/src"),
      13: import("../../Templates/aiktech-bakery/src"),
      16: import("../../Templates/aiktech-home-decor/src"),
      15: import("../../Templates/aiktech-bakery-advanced/src"),
      14: import("../../Templates/informative/src"),
    };
    
    const fetchComponents = async () => {
      const templateImport = await categoryTemplates[categoryId];


      if (templateImport) {

        try {
          const module = templateImport;
          const {
            Navbar,
            Banner,
            Category,
            HomePageCategoryComponent,
            WhyUs,
            FooterComponent,
          } = module;

          const title = tenantDetails.title;
          const HomePageDetails = tenantDetails.template_details?.home;

          const {
            header,
            faqs,
            banner,
            categories,
            featured_categories,
            why_us,
            offers,
            footer,
          } = HomePageDetails;

          toggleComponents([
            {
              status: false,
              show: true,
              name: "Navbar",
              component: <Navbar data={{ title: title, ...header }} />,
            },
            {
              status: false,
              show: true,
              name: "Banner",
              component: <Banner data={banner} />,
            },
            {
              status: false,
              show: categories,
              name: "Category",
              component: <Category />,
            },
            {
              status: false,
              show: featured_categories,
              name: "HomepageCategory",
              component: <HomePageCategoryComponent />,
            },
            {
              status: false,
              show: why_us,
              name: "WhyUs",
              component: <WhyUs data={{ title: title }} />,
            },
            {
              status: false,
              show: true,
              name: "Footer",
              component: <FooterComponent data={{ title: title, ...footer }} />,
            },
          ]);

        } catch (error) {
          console.error('Error loading dynamic component:', error);
        }
      }
    };

    fetchComponents();
  }, [categoryId, tenantDetails]);

  useEffect(() => {
    setComponents();
  }, [components]);

  const { subdomain = "", custom_domain = "" } = finalDetails;
  const navigate = () => {
    // Your navigate logic here
    let redirectingUrl = ''

    if(custom_domain !== null && custom_domain.length > 0){
      redirectingUrl = `https://${custom_domain}`;
    }else{
      redirectingUrl = `https://${subdomain}.aiktech.in/`;
    }

    window.open(redirectingUrl, '_blank');
  };

  const setComponents = async () => {
    if (currComponentInd >= 6) {
      toggleLoading(false);
      return;
    }

    await new Promise((resolve) => {
      setTimeout(() => {
        toggleComponents((prevComponents) => {
          const comp = [...prevComponents];
          if (comp[currComponentInd]) comp[currComponentInd]["status"] = true;
          return comp;
        });

        window.scrollTo({ top: currComponentInd * 400, behavior: 'smooth' });
        resolve();
      }, 4000);
    });

    setCurrComponentInd((prev) => prev + 1);
  };

  if (!useLocation().state?.categoryId) {
    return (
      <div
        className="h-full w-full flex flex-col gap-20 justify-center"
        style={{
          paddingTop: '2rem',
          minHeight: '40vh',
        }}
      >
        <span className="text-center">
          No template to show
        </span>
        <div className="flex flex-row justify-center">
          <button
            onClick={() => {
              history.push('/register')
            }}
            className="btn-inactive btn-md"
          >
            Create One
          </button>
        </div>
      </div>
    )
  }

  return (
    <div
      id={'builder-container'}
      className="relative builder"
      style={{
        minHeight: "90vh",
        overflow: 'hidden',
      }}
    >
      <div className="relative">
        {loading && (
          <div
            className="overlay"
            style={{
              position: "absolute",
              top: "0",
              background: "#00000010",
              backdropFilter: "blur(2px)",
              height: "100%",
              width: "100%",
            }}
          />
        )}
        {components.map((item) => {
          if (item.status && item.show)
            return <LoadComponent>{item.component}</LoadComponent>;
        })}
      </div>

      {
        <PreviewLoader complete={loading} navigate={navigate} />
      }

      {
        !loading &&
        <div
          className="flex justify-center"
          style={{
            margin: '2rem 0',
          }}
        >
          <button
            onClick={navigate}
            className="btn-active btn-md"
          >
            Preview Your Website
          </button>
        </div>
      }
    </div>
  );
};

export default Builder;
