import React from "react";
import WhyShopStepCard from "../WhyShopStepCard";
import "./WhyShopBottomContainer.scss";
import step1 from "../../../Assets/Images/why shop with us/step1.svg";
import step2 from "../../../Assets/Images/why shop with us/step2.svg";
import step3 from "../../../Assets/Images/why shop with us/step3.svg";
import mobileStep1 from "../../../Assets/Images/why shop with us/step1.svg";
import mobileStep2 from "../../../Assets/Images/why shop with us/step2.svg";
import mobileStep3 from "../../../Assets/Images/why shop with us/step3.svg";
import wave from "../../../Assets/Images/why shop with us/icon/wave.svg";
import WhyShopStepCardMobile from "./WhyShopStepCardMobile";

const WhyShopBottomContainer = () => {
  return (
    <div className="bottom-whyshop">
      <div className="whyshop-pc">
        <h2>3 Easy steps, Thats it !</h2>
        <div className="bottom-whyshop-container">
          <img src={wave} alt="" className="wave" />
          <div className="steps-wrapper">
            <WhyShopStepCard
              image={step1}
              number={1}
              text="Sign Up with your email address  Or mobile number."
            />
            <WhyShopStepCard
              image={step2}
              number={2}
              text="Add your Business details - Name, Logo, Address."
            />
            <WhyShopStepCard
              image={step3}
              number={3}
              text="Select your plan. Upload your product inventory in an excel sheet and you are ready to sell through your app and website."
            />
          </div>
        </div>
      </div>
      <div className="whyshop-mobile">
        <h2>Three Easy Steps</h2>
        <WhyShopStepCardMobile
          image={mobileStep1}
          bgcolor={"#8599FA"}
          text={"Sign Up with your email address  Or mobile number."}
        />
        <WhyShopStepCardMobile
          image={mobileStep2}
          bgcolor={"#ffffff"}
          text="Add your Business details - Name, Logo, Address."
        />
        <WhyShopStepCardMobile
          image={mobileStep3}
          bgcolor={"#8599FA"}
          text="Select your plan. Upload your product inventory in an excel sheet and you are ready to sell through your app and website."
        />
      </div>
    </div>
  );
};

export default WhyShopBottomContainer;
