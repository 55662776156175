import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import logoImg from "../../Assets/Icons/new_logo.svg";
import barImg from "../../Assets/Icons/bar.png";
import Button from "../Button";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { debounce, formatName } from "../../Utils";
import dashboardIcon from "../../Assets/Icons/dashboard.png";
import profileIcon from "../../Assets/Icons/profile.svg";
import Menu from "../Menu";
import { getToken } from "../../Utils";
import { LOGOUT_USER } from "../../Redux/actions/actionTypes";
import { actionsCreator } from "../../Redux/actions/actionsCreator";
import { toast } from "react-toastify";

const mapStateToProps = ({ auth = {} }) => ({
  auth,
});

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const { auth = {} } = useSelector(mapStateToProps);
  const {  user = {} } = auth;
  const dispatch = useDispatch()
  
  const logOut = () => {
    dispatch(actionsCreator.LOGOUT_USER());
    toast.error("User is logged out successfully");
   
  };
  const isLoggedIn = getToken()
  const menuItems = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Dashboard",
      path: "/cms",
    },
    {
      name: "Ticket",
      path: "/crm",
    },
    {
      name: "Services",
      path: "/",
    },
    {
      name: "About Us",
      path: "/",
    },
    {
      name: "Our Clients",
      path: "/",
    },
    {
      name: "Contact Us",
      path: "/",
    },
    {
      name : 'Request a quote',
      path : '/'
    }
  ];
  const logoutOption = [
    {
      name: "logout",
      action: logOut
    },
    
  ];
  
  const history = useHistory();
  const navigate = (path) => {
    history.push(path);
  };

  const onMenuClick = () => {
    setShowMenu(!showMenu);
  };
  const { name = "" } = user || {};
  const formattedName = formatName(name);
  const menuText = `Hey ${formattedName}!`;

  return (
    <div className="Header">
      <div className="Header__Logo" onClick={() => navigate("")}>
        <img src={logoImg} alt="Logo" className="Header__Logo__Img" />
        <img src={barImg} className="Header__Logo__Bar" onClick={onMenuClick} />
      </div>
      <div className={`Header__Menu ${showMenu ? "ShowMenu" : ""}`}>
        {menuItems.map((item, index) => {

          if(index == 1){
            if(isLoggedIn){ 
              return (
                <button
                  type="button"
                  className="Header__Menu__Item btn-none"
                  onClick={() => navigate(item.path)}
                  style={{
                    fontWeight : '200'
                  }}
                >
                  {item.name}
                </button>
              )
            }else{
              return null
            }
          }else{
            return (
              <button
                type="button"
                className="Header__Menu__Item btn-none"
                style={{
                  fontWeight : '200'
                }}
                onClick={() => navigate(item.path)}
              >
                {item.name}
              </button>
            );
          }

        })}


        {/* <div className="Header__Menu__Actions">
          <Button
            text="Request a quote"
            className="Radius__Button Primary__Button"
          />
        </div> */} 
        {
          !isLoggedIn 
          ?
          (
            <button
              className="btn-inactive btn-sm"
              onClick={() => {
                navigate('/login')
              }}
            >
              Log in
            </button>
          )
          :
          (
            <div className="Navbar__Container__DashboardActions">
            <div className="Navbar__Container__DashboardActions__Profile">
              <Menu
                items={logoutOption}
                menuIcon={profileIcon}
                menuText={menuText}
              />
            </div>
          </div>
          )
        }
      </div>
    </div>
  );
}
