import React from 'react'

import './helper.scss'
import { Navigation, Footer } from './v1/Components'

import {
    BannerComponent, 
    // CategoryComponent,
    HomePageCategory,
    WhyUsComponent
} from './v1/Pages/Home/Home'

export const Navbar = ({data}) => {
    return (
        <Navigation data={data}/>
    )
}

export const Banner = () => {
    return (
        <BannerComponent /> 
    )
}

export const Category = () => {
    return (
        <></>
    )
}

export const HomePageCategoryComponent = () => {
    return (
        <HomePageCategory/>
    )
}

export const WhyUs = () => {
    return (
        <WhyUsComponent/>
    )
}

export const FooterComponent = ({data}) => {
    return (
        <Footer data={data}/>
    ) 
}

export default function Home (){
    return (
        <>
            <Navbar data={{title : 'Prince'}}/>
            <BannerComponent/>
            <HomePageCategoryComponent/>
            <WhyUs/>
            <FooterComponent data={{title : 'Prince'}}/>
        </>
    )
}